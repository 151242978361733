import { Grid2 } from '@mui/material'
import { groupBy, orderBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdminFeatureExplorer from './AdminFeatureExplorer'
import useBoolean from 'utils/customHook/useBoolean'
import ConfirmModal from 'components/modal/ConfirmModal'
import DialogAdminFeature from './DialogAdminFeature'
import useTitles from 'utils/customHook/useTitles'
import { CardTable } from 'components/datatable/Table'
import useActions from 'utils/customHook/useActions'
import { NEW } from 'components/action/ActionConstant'
import { hasPermission } from 'utils/HabilitationUtil'
import { deleteAdminFeature, fetchAdminFeatures } from 'gdl/slices/AdminFeatureSlice'

const SettingLicenseApp = ({

}) => {
    const dispatch = useDispatch()
    const {
        user,
        adminFeatures,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
        adminFeatures: store.adminFeatures,
    }), shallowEqual)

    useTitles(() => [{
        label: 'Paramètrage licence',
        href: '/settingLicense',
    }], [])

    useEffect(() => {
        dispatch(fetchAdminFeatures())
    }, [])

    const [selectedAdminFeature, setSelectedAdminFeature] = useState({})
    const {
        value: isDialogOpen,
        setTrue: onOpenDialog,
        setFalse: onCloseDialog,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)

    useActions(() => [{
        type: NEW,
        onClick: onOpenDialog,
        displayed: hasPermission(user, 'settingLicenses', 'create'),
    }], [])

    const formattedGroupedAdminFeatures = useMemo(() => {
        const orderedFeatures = orderBy(adminFeatures.allIds.map(id => adminFeatures.byId[id]), ['typeFeature', 'code'], ['desc', 'asc'])
        const groupedAdminFeatures = groupBy(orderedFeatures, s => s.parent ?? 'headTree')

        const formatAdminFeatures = (filteredAdminFeatures = []) => {
            return filteredAdminFeatures.map(({ id, code, name, typeFeature, description, pluggedInFrontend }) => {
                const subFeatures = formatAdminFeatures(groupedAdminFeatures[id])
                return {
                    id,
                    label: `[${code}] ${name}`,
                    typeFeature,
                    description,
                    pluggedInFrontend,
                    subFeatures,
                }
            })
        }

        return formatAdminFeatures(groupedAdminFeatures.headTree)
    }, [adminFeatures.allIds, adminFeatures.byId])

    const existInTree = useCallback((id, treeFeatures = []) => {
        return treeFeatures.some(f => f.id === id || existInTree(id, f.subFeatures))
    }, [])

    const adminFeaturesNotInTree = adminFeatures.allIds.filter(id => !existInTree(id, formattedGroupedAdminFeatures))

    const onCreate = id => {
        setSelectedAdminFeature({ parent: id })
        onOpenDialog()
    }
    const onUpdate = id => {
        setSelectedAdminFeature(adminFeatures.byId[id])
        onOpenDialog()
    }
    const onDelete = id => {
        setSelectedAdminFeature({ id })
        onOpenConfirm()
    }

    return (
        <>
            <Grid2 container spacing={1} alignItems='flex-start' sx={{ padding: '5px 15%' }}>
                {formattedGroupedAdminFeatures.map(adminFeature => (
                    <Grid2 key={adminFeature.code} size={12}>
                        <AdminFeatureExplorer
                            adminFeature={adminFeature}
                            actions={[
                                {
                                    icon: 'add_circle_outline',
                                    onClick: onCreate,
                                    displayed: hasPermission(user, 'settingLicenses', 'create'),
                                },
                                {
                                    icon: 'edit',
                                    onClick: onUpdate,
                                    displayed: hasPermission(user, 'settingLicenses', 'update'),
                                },
                                {
                                    icon: 'delete_outlined',
                                    onClick: onDelete,
                                    displayed: hasPermission(user, 'settingLicenses', 'delete'),
                                },
                            ]}
                        />
                    </Grid2>
                ))}
                {adminFeaturesNotInTree.length > 0 && (
                    <Grid2 size={12}>
                        <CardTable
                            title={'Fonctionnalité non présente dans l\'arbre'}
                            headers={['id', 'code', 'description', { key: 'idParent', value: 'Id parent' }, { key: 'codeParent', value: 'Code parent' }]}
                            rows={adminFeaturesNotInTree.map(af => ({
                                id: af.id,
                                code: af.code,
                                description: af.description,
                                idParent: af.parent,
                                codeParent: adminFeatures.byId[af.parent]?.code,
                            }))}

                            lineActions={[
                                {
                                    icon: 'edit',
                                    onClick: ({ id }) => {
                                        setSelectedAdminFeature(adminFeatures.byId[id])
                                        onOpenDialog()
                                    },
                                    displayed: hasPermission(user, 'settingLicenses', 'update'),
                                },
                                {
                                    icon: 'delete',
                                    onClick: ({ id }) => {
                                        setSelectedAdminFeature({ id })
                                        onOpenConfirm()
                                    },
                                    displayed: hasPermission(user, 'settingLicenses', 'delete'),
                                },
                            ]}
                        />
                    </Grid2>
                )}
            </Grid2>
            <DialogAdminFeature
                isOpen={isDialogOpen}
                onClose={() => {
                    setSelectedAdminFeature()
                    onCloseDialog()
                }}
                onValidate={() => dispatch(fetchAdminFeatures())}
                adminFeature={selectedAdminFeature}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title='Êtes-vous sûr de vouloir supprimer cette fonctionnalité ?'
                onValidate={() => {
                    dispatch(deleteAdminFeature(selectedAdminFeature.id)).then(() => {
                        dispatch(fetchAdminFeatures())
                        setSelectedAdminFeature()
                        onCloseConfirm()
                    })
                }}
                onClose={onCloseConfirm}
            />
        </>
    )
}

export default SettingLicenseApp