import ApplicationConf from 'conf/ApplicationConf'
import { CustomerActionConstant } from 'customer/reducers/CustomerReducer'
import { catchFunctionBuilder, genericPromise2, thenFunctionCreateBuilder, thenFunctionDeleteBuilder, thenFunctionUpdateBuilder } from 'utils/ActionUtils'

const CustomerAction = {
    fetchCustomers: () => dispatch => genericPromise2(ApplicationConf.gdl.customers())
        .then((list = []) => dispatch(CustomerActionConstant.customers(list)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération des clients', dispatch)),

    fetchCustomer: id => dispatch => genericPromise2(ApplicationConf.gdl.customer(id))
        .then(res => dispatch(CustomerActionConstant.customer(res)))
        .catch(catchFunctionBuilder('Erreur lors de la récupération d\'un client', dispatch)),
    createCustomer: customer => dispatch => genericPromise2(ApplicationConf.gdl.customers(), { body: customer, method: 'POST' })
        .then(thenFunctionCreateBuilder('Client créée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la création d\'un client', dispatch)),
    updateCustomer: customer => dispatch => genericPromise2(ApplicationConf.gdl.customers(), { body: customer, method: 'PUT' })
        .then(thenFunctionUpdateBuilder('Client modifiée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la modification d\'un client', dispatch)),
    deleteCustomer: id => dispatch => genericPromise2(ApplicationConf.gdl.customer(id), { method: 'DELETE' })
        .then(thenFunctionDeleteBuilder('Client supprimée avec succès', dispatch))
        .catch(catchFunctionBuilder('Erreur lors de la suppression d\'un client', dispatch)),
}

export default CustomerAction