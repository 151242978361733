// import SieauAction from '../components/sieau/SieauAction'
import { isNil } from 'lodash'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        // AppStore.dispatch(push('/login'))
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkStatus = (obj, response) => {
    if (!obj[`${response.status}`]) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[`${response.status}`](response)
}

const checkAuth = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const checkError = (json = {}, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        throw new Error(json.error)
    }
    return json
}

const getJson = response => response.json()

const getAuthorization = () => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('aquaparc-token')}`,
    Module: 'WEB',
    // Mode: 'no-cors',
})

const genericPromise2 = (url, options = {}, overrideStatus = {}) => {
    return fetch(url, {
        method: options.method ?? 'GET',
        body: options.body ? JSON.stringify(options.body) : undefined,
        headers: getAuthorization(),
        signal: options.signal,
    })
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const catchFunctionBuilder = (message = 'Erreur', dispatch) => err => {
    // eslint-disable-next-line no-console
    console.error(err)
    dispatch(ToastActionConstant.error(message))
}

const thenFunctionCreateBuilder = (message = 'Elément créé avec succès', dispatch) => res => {
    if (isNil(res.create)) throw new Error('Erreur back')
    if (res.create === 0) throw new Error('Aucun ajout effectué')
    dispatch(ToastActionConstant.success(message))
    return res
}

const thenFunctionUpdateBuilder = (message = 'Elément créé avec succès', dispatch) => res => {
    if (isNil(res.update)) throw new Error('Erreur back')
    if (res.update === 0) throw new Error('Aucune modification effectué')
    dispatch(ToastActionConstant.success(message))
    return res
}

const thenFunctionDeleteBuilder = (message = 'Elément créé avec succès', dispatch) => res => {
    if (isNil(res.delete)) throw new Error('Erreur back')
    if (res.delete === 0) throw new Error('Aucune suppression effectué')
    dispatch(ToastActionConstant.success(message))
    return res
}


export {
    genericPromise2,
    catchFunctionBuilder,
    thenFunctionCreateBuilder,
    thenFunctionUpdateBuilder,
    thenFunctionDeleteBuilder,
}
