import { Card, CardContent, Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { difference, isNil } from 'lodash'
import useListIndexed from 'utils/customHook/useListIndexed'
import useDebounce from 'utils/customHook/useDebounce'
import { searchAllCharacters } from 'utils/StringUtil'
import { ALIGN, CardTable } from './Table'
import Input from 'components/form/Input'

const SelectionTable = ({
    onChange = () => { },

    rows = [],
    selectedIds = [],

    headers = [],
    listTitle = '',
    selectedHeaders = headers,
    selectedListTitle = '',
    maxHeightTable = '55vh',

    filterField,
    filterFunction = list => list,
    defaultFilter = {},
}) => {
    const [filter, setFilter] = useState(defaultFilter)

    const indexedRows = useListIndexed(rows, 'id')
    const rowIds = useMemo(() => rows.map(d => d.id), [rows])

    const addAll = listRows => {
        const listId = listRows.map(({ id }) => id)
        onChange([...selectedIds, ...listId])
    }

    const deleteAll = listRows => {
        const listId = listRows.map(({ id }) => id)
        onChange(difference(selectedIds, listId))
    }

    const onAdd = row => {
        onChange([...selectedIds, row.id])
    }

    const onDelete = row => {
        onChange(selectedIds.filter(id => id !== row.id))
    }

    const formattedUnselectedList = useMemo(() => {
        const unselectedList = difference(rowIds, selectedIds)
        return unselectedList.map(id => indexedRows[id])
    }, [indexedRows, rowIds, selectedIds])
    const filteredUnselectedList = useMemo(() => filterFunction(formattedUnselectedList, filter), [filter, formattedUnselectedList])

    const formattedSelectedList = useMemo(() => selectedIds.map(id => indexedRows[id]), [indexedRows, selectedIds])
    const selectedListFiltered = useMemo(() => filterFunction(formattedSelectedList, filter), [filter, formattedSelectedList])

    const customListTitle = (
        <>
            <span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{listTitle}</span>
            <span style={{ fontSize: '1.4rem', paddingLeft: '5px' }}>({filteredUnselectedList.length}/{formattedUnselectedList.length} {formattedUnselectedList.length > 1 ? 'Eléments' : 'Elément'})</span>
        </>
    )

    const customSelectedListTitle = (
        <>
            <span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{selectedListTitle}</span>
            <span style={{ fontSize: '1.4rem', paddingLeft: '5px' }}>({selectedListFiltered.length}/{formattedSelectedList.length} {formattedSelectedList.length > 1 ? 'Eléments' : 'Elément'})</span>
        </>
    )

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                {
                    !isNil(filterField) && (
                        <Card>
                            <CardContent>
                                {filterField({ filter, setFilter })}
                            </CardContent>
                        </Card>
                    )
                }
            </Grid2>
            <Grid2 container size={12} spacing={1}>
                <Grid2 size={6}>
                    <CardTable
                        title={customListTitle}
                        actions={[{
                            onClick: () => addAll(filteredUnselectedList),
                            icon: 'add',
                            tooltip: 'Tout ajouter',
                            displayed: !isNil(addAll),
                        }]}
                        hideNbElements

                        rows={filteredUnselectedList}
                        headers={headers}

                        lineActions={[{ icon: 'add', align: ALIGN.RIGHT }]}
                        onClickRow={onAdd}
                        maxHeight={maxHeightTable}

                        data-cy='unselectedTable'
                    />
                </Grid2>
                <Grid2 size={6}>
                    <CardTable
                        title={customSelectedListTitle}
                        actions={[{
                            onClick: () => deleteAll(selectedListFiltered),
                            icon: 'close',
                            tooltip: 'Tout retirer',
                            displayed: !isNil(deleteAll),
                        }]}
                        hideNbElements

                        rows={selectedListFiltered}
                        headers={selectedHeaders}

                        lineActions={[{ icon: 'close' }]}
                        onClickRow={onDelete}
                        maxHeight={maxHeightTable}

                        data-cy='selectedTable'
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

SelectionTable.propTypes = {
    onChange: PropTypes.func.isRequired,

    rows: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            // every key from headers and selectedHeaders should exist in this object
        })),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            // every key from headers and selectedHeaders should exist in this object
        })),
    ]).isRequired,
    selectedIds: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,

    headers: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            key: PropTypes.string.isRequired,
        }),
    ])).isRequired,
    listTitle: PropTypes.string.isRequired,
    selectedHeaders: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            key: PropTypes.string.isRequired,
        }),
    ])),
    selectedListTitle: PropTypes.string.isRequired,
    selectedActions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.shape({
            icon: PropTypes.string,
            tooltip: PropTypes.string,
            onClick: PropTypes.func,
            color: PropTypes.string,
            displayed: PropTypes.bool,
            'data-cy': PropTypes.string,
        }),
    ])),
    maxHeightTable: PropTypes.string,

    filterField: PropTypes.func, // can be a function or a component, filter and setFilter are passed in props
    filterFunction: PropTypes.func, // (list, filter) => return filteredList
    defaultFilter: PropTypes.shape({}),
    uniqSelection: PropTypes.bool,
}

const SimpleFilterFunction = (list, { searchValue = '' }) => {
    const searchValueFormat = searchAllCharacters(searchValue)
    return searchValue ? list.filter(p => p.labelSearch.includes(searchValueFormat)) : list
}

const SimpleFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={6}>
                <Input
                    label='Rechercher'
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid2>
        </Grid2>
    )
}

SimpleFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

export default SelectionTable
export {
    SimpleFilterFunction,
    SimpleFilterField,
}