import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTable } from 'components/datatable/Table'
import Input from 'components/form/Input'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useTitles from 'utils/customHook/useTitles'
import { getDate } from 'utils/DateUtil'

const CustomerDasboardApp = () => {
    const { id } = useParams()
    const {
        customer,
        instances,
    } = useSelector(store => ({
        customer: store.CustomerReducer.customer,
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    useTitles(() => [{
        label: 'Clients',
        href: '/customer',
    }, {
        label: customer?.name ?? 'Inconnu',
        href: `/customer/${id}`,
    }, {
        label: 'Dashboard',
        href: `/customer/${id}/dashboard`,
    }], [id, customer])

    const formattedCustomerInstances = customer?.instances.map(ci => ({
        instance: ci.instance,
        name: instances.find(i => i.id === ci.instance)?.name ?? 'Inconnue',
        type: ci.instanceType,
    }))

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={3}>
                                <Input
                                    label='Nom'
                                    value={customer?.name}
                                    disabled
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Input
                                    label='Mnémonique'
                                    value={customer?.mnemonic}
                                    disabled
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Input
                                    label='Date de début'
                                    value={getDate(customer?.startDate)}
                                    disabled
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Input
                                    label='Date de fin'
                                    value={getDate(customer?.endDate)}
                                    disabled
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    label='Description'
                                    value={customer?.description}
                                    multiline
                                    disabled
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={6}>
                <CardTable
                    title='Instances'

                    rows={formattedCustomerInstances}
                    headers={[{ key: 'name', value: 'Instance' }, { key: 'type', value: 'Type d\'instance' }]}
                />
            </Grid2>
            <Grid2 size={6}>
                <CardTable
                    title='Intervenant'

                    rows={customer?.contributors}
                    headers={[{ key: 'name', value: 'Nom' }, { key: 'type', value: 'Type d\'intervenant' }]}
                />
            </Grid2>
        </Grid2>
    )
}

export default CustomerDasboardApp