import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import SelectionTable from 'components/datatable/SelectionTable'
import { BasicDialogTitle } from './DialogComponents'

const SelectionTableModal = ({
    isOpen = false,
    onClose = () => { },
    onValidate = () => { },
    title = '',

    rows = [],
    selectedIds = [],

    headers = [],
    listTitle = '',
    selectedHeaders,
    selectionListTitle = '',
    maxHeightTable = '55vh',

    filterField,
    filterFunction = list => list,
    defaultFilter = {},
}) => {
    const [ids, setIds] = useState(selectedIds)

    useEffect(() => {
        if (!isOpen) return
        setIds(selectedIds)
    }, [selectedIds, isOpen])

    return (
        <Dialog
            fullWidth
            maxWidth='xl'
            PaperProps={{
                sx: {
                    minHeight: '90vh',
                    maxHeight: '90vh',
                },
            }}
            open={isOpen}
        >
            <BasicDialogTitle onClose={onClose}>
                {title}
            </BasicDialogTitle>
            <DialogContent>
                <SelectionTable
                    onChange={setIds}

                    rows={rows}
                    selectedIds={ids}

                    headers={headers}
                    selectedHeaders={selectedHeaders}
                    listTitle={listTitle}
                    selectedListTitle={selectionListTitle}
                    maxHeightTable={maxHeightTable}

                    filterField={filterField}
                    filterFunction={filterFunction}
                    defaultFilter={defaultFilter}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(ids)} variant='contained'>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SelectionTableModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,

    rows: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            // every key from headers and selectedHeaders should exist in this object
        })),
        PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            // every key from headers and selectedHeaders should exist in this object
        })),
    ]).isRequired,
    selectedIds: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
    ]),

    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    listTitle: PropTypes.string.isRequired,
    selectedHeaders: PropTypes.arrayOf(PropTypes.string),
    selectionListTitle: PropTypes.string.isRequired,
    maxHeightTable: PropTypes.string,

    filterField: PropTypes.func, // can be a function or a component, filter and setFilter are passed in props
    filterFunction: PropTypes.func, // (list, filter) => return filteredList
    defaultFilter: PropTypes.shape({}),

    titleBackgroundColor: PropTypes.string,
    color: PropTypes.string,
    openProgressBar: PropTypes.bool,
}

export default SelectionTableModal