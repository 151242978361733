import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { CardTable } from 'components/datatable/Table'
import ConfirmModal from 'components/modal/ConfirmModal'
import Checkbox from 'components/form/Checkbox'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import Input from 'components/form/Input'
import ApplicationConf from 'conf/ApplicationConf'
import InstanceAction from 'instance/actions/InstanceAction'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useFetch from 'utils/customHook/useFetch'
import useTitles from 'utils/customHook/useTitles'
import PropTypes from 'prop-types'
import { NEW } from 'components/action/ActionConstant'
import { greyBlue } from 'AppTheme'
import Select from 'components/form/Select'
import { INSTANCE_TYPE } from 'instance/constants/InstanceConstants'
import { push } from '@lagunovsky/redux-react-router'
import { InstanceParameterDialog } from './InstanceDescriptionApp'
import { hasPermission } from 'utils/HabilitationUtil'

const PopinInstance = ({
    isOpen = false,
    close = () => {},
    reload = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        value: isLoading,
        setTrue: loading,
        setFalse: notLoading,
    } = useBoolean(false)

    const {
        value: isChainCreate,
        toggle: toggleChainCreate,
    } = useBoolean(false)

    const {
        value: isPopinOpen,
        setTrue: onOpenPopin,
        setFalse: onClosePopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)

    const [selectedParameter, setSelectedParameter] = useState()

    const [name, setName] = useState()
    const [server, setServer] = useState()
    const [instanceType, setInstanceType] = useState()
    const [url, setUrl] = useState()
    const [parameters, setParameters] = useState([])

    useEffect(() => {
        if (isOpen) {
            setName()
            setServer()
            setInstanceType()
            setUrl()
            setParameters([])
        }
    }, [isOpen])

    const resetAllField = () => {
        setName()
        setServer()
        setInstanceType()
        setUrl()
        setParameters([])
    }

    const validate = () => {
        loading()
        dispatch(InstanceAction.createInstance({ id: -1, name, server, instanceType, url, parameters }))
            .then(res => {
                if (!res) return
                reload()
                if (!isChainCreate) {
                    close()
                } else {
                    resetAllField()
                }
            })
            .finally(notLoading)
    }

    const onDeleteParameter = row => {
        setSelectedParameter(row.parameter)
        onOpenConfirm()
    }

    const onEditParameter = row => {
        setSelectedParameter(row.parameter)
        onOpenPopin()
    }

    const parameter = parameters.find(p => p.parameter === selectedParameter)

    return (
        <Dialog
            open={isOpen}
            maxWidth={'xl'}
        >
            <BasicDialogTitle onClose={close}>
                Instance
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Grid2 container spacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardContent>
                                <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                                    <Grid2 size={6}>
                                        <Input
                                            label={'Nom'}
                                            value={name}
                                            onChange={setName}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            label={'Serveur'}
                                            value={server}
                                            onChange={setServer}
                                            type='float'
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            label={'Type d\'instance'}
                                            items={INSTANCE_TYPE}
                                            value={instanceType}
                                            onChange={setInstanceType}
                                            undefinedValue
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            label={'Url'}
                                            value={url}
                                            onChange={setUrl}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <CardTable
                            title='Paramètrage'

                            rows={parameters}
                            headers={[{ key: 'parameter', value: 'Paramètre' }, { key: 'value', value: 'Valeur' }]}

                            actions={[{
                                icon: 'add',
                                onClick: onOpenPopin,
                                tooltip: 'Nouveau paramètre',
                            }]}
                            lineActions={[
                                { icon: 'delete', onClick: onDeleteParameter },
                                { icon: 'edit', onClick: onEditParameter },
                            ]}
                        />
                        <InstanceParameterDialog
                            isOpen={isPopinOpen}
                            parameter={parameter}
                            onClose={() => {
                                setSelectedParameter()
                                onClosePopin()
                            }}
                            onValidate={newParam => {
                                const formattedParam = { ...newParam, instanceId: -1 }
                                setParameters(params => {
                                    if (isNil(selectedParameter)) return [...params, formattedParam]
                                    return params.map(p => p.parameter === selectedParameter ? formattedParam : p)
                                })
                                setSelectedParameter()
                                onClosePopin()
                            }}
                        />
                        <ConfirmModal
                            isOpen={isConfirmOpen}
                            title='Êtes-vous sûr de vouloir supprimer ce paramètrage ?'
                            onValidate={() => {
                                setParameters(params => params.filter(p => p.parameter !== selectedParameter))
                                onCloseConfirm()
                            }}
                            onClose={onCloseConfirm}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={'Créer'}
                onValidate={validate}
                isLoading={isLoading}
            >
                <Checkbox
                    label='Saisir une autre instance'
                    checked={isChainCreate}
                    onToggle={toggleChainCreate}
                    labelStyle={{ color: '#555', fontWeight: 'normal' }}
                />
            </BasicDialogAction>
        </Dialog>
    )
}

PopinInstance.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    reload: PropTypes.func,
}

const InstancesApp = ({

}) => {
    const dispatch = useDispatch()
    const { user } = useSelector(store => ({ user: store.AdminReducer.user }), shallowEqual)

    const {
        value: isOpenPopin,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)

    useTitles(() => [{
        label: 'Instance',
        href: '/instance',
    }], [])

    const {
        data = [],
        reload,
    } = useFetch(ApplicationConf.instance.all(), undefined)

    const formattedInstances = data.map(instance => ({
        id: instance.id,
        name: { value: instance.name },
        server: { value: instance.server },
        instanceType: { value: INSTANCE_TYPE.find(t => t.value === instance.instanceType)?.label ?? 'Inconnue' },
        url: {
            value: instance.url,
            fontColor: greyBlue,
            onClick: instance.url ? () => window.open(instance.url, '_blank') : undefined,
        },
    }))

    useActions(() => [{
        type: NEW,
        onClick: openPopin,
        displayed: hasPermission(user, 'instances', 'create'),
    }], [])

    return (
        <div style={{ margin: '10', paddingBottom: '50px' }}>
            <CardTable
                title={'Instances'}
                color={'#161832'}

                rows={formattedInstances}
                headers={[{ key: 'name', value: 'Nom' }, { key: 'server', value: 'Serveur' }, { key: 'instanceType', value: 'Type d\'instance' }, { key: 'url', value: 'Url' }]}
                onClickRow={({ id }) => dispatch(push(`/instance/${id}`))}

                actions={[{
                    icon: 'replay',
                    onClick: reload,
                    tooltip: 'Recharger',
                }]}
            />
            <PopinInstance
                isOpen={isOpenPopin}
                close={closePopin}
                reload={reload}
            />
        </div>
    )
}

export default InstancesApp