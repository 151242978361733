import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid2, Icon, IconButton, Tooltip } from '@mui/material'
import { alpha } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { RichTreeView } from '@mui/x-tree-view/RichTreeView'
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2'
import {
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2'
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon'
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider'
import { isUndefined } from 'lodash'
import DescriptionIcon from '@mui/icons-material/Description'

const Action = ({
    onClick = () => {},
    icon,
    color = '#161832',
}) => {
    return (
        <IconButton
            sx={{ fontSize: '24px', padding: '3px' }}
            onClick={e => {
                e.stopPropagation()
                onClick()
            }}
        >
            <Icon fontSize='inherit' sx={{ color }}>{icon}</Icon>
        </IconButton>
    )
}

const CustomLabel = ({ item, actions, children, ...other }) => (
    <TreeItem2Label {...other}>
        <Grid2 container spacing={1} alignItems='center'>
            <Grid2 container size='grow'>
                <Grid2 size='auto'>
                    <Typography sx={{ fontSize: '1rem' }}>{children}</Typography>
                </Grid2>
                {item.description && (
                    <Grid2 size='auto'>
                        <Tooltip title={item.description}>
                            <DescriptionIcon />
                        </Tooltip>
                    </Grid2>
                )}
                <Grid2 size='grow' />
                <Grid2 size='auto'>
                    <Typography sx={{ fontSize: '1rem', width: '150px' }}>{item.price} €</Typography>
                </Grid2>
            </Grid2>
            {actions.map(({ icon, onClick }, i) => (
                <Grid2 key={i} size='auto'>
                    <Action
                        icon={icon}
                        onClick={() => onClick(item.id)}
                    />
                </Grid2>
            ))}
        </Grid2>
    </TreeItem2Label>
)

const CustomTreeItem = React.forwardRef((props, ref) => {
    const {
        id, itemId, label, disabled, children, // for useTreeItem2
        actions, // for getLabelProps
        ...other // for getRootProps
    } = props

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        status,
        publicAPI,
        getGroupTransitionProps,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })
    const item = publicAPI.getItem(itemId)

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content {...getContentProps()} sx={{ borderRadius: '8px' }}>
                    <TreeItem2IconContainer {...getIconContainerProps()}>
                        <TreeItem2Icon status={status} />
                    </TreeItem2IconContainer>
                    <CustomLabel
                        {...getLabelProps({ item, actions })}
                    />
                </TreeItem2Content>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()}
                    sx={{
                        marginLeft: '15px',
                        paddingLeft: '8px',
                        borderLeft: `1px dashed ${alpha('#000000', 0.3)}`,
                    }}
                />}
            </TreeItem2Root>
        </TreeItem2Provider>
    )
})

const addDepth = (item, depth = 0) => {
    return {
        ...item,
        depth,
        children: item.subFeatures?.map(it => addDepth(it, depth + 1)),
    }
}

const CommercialFeatureExplorer = ({
    commercialFeature,
    actions = [],
}) => {
    const [expandedItems, setExpandedItems] = useState([])

    const items = useMemo(() => {
        const list = isUndefined(commercialFeature) ? [] : [commercialFeature]
        return list.map(item => addDepth(item, 0))
    }, [commercialFeature])

    const filteredActions = actions.filter(a => a.displayed || isUndefined(a.displayed))

    return (
        <Card>
            <CardContent>
                <RichTreeView
                    items={items}
                    expandedItems={expandedItems}
                    onExpandedItemsChange={(_, ids) => setExpandedItems(ids)}
                    slots={{ item: CustomTreeItem }}
                    slotProps={{
                        item: {
                            actions: filteredActions,
                        },
                    }}
                />
            </CardContent>
        </Card>
    )
}

CommercialFeatureExplorer.propTypes = {
    commercialFeature: PropTypes.shape({}),
    actions: PropTypes.arrayOf(PropTypes.shape({})),
}

export default CommercialFeatureExplorer