import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    customers: [],
    customer: undefined,
}

const store = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        customers: (state, action) => {
            state.customers = action.payload
        },
        customer: (state, action) => {
            state.customer = action.payload
        },
        reset: state => {
            state.customers = initialState.customers
        },
        resetCustomer: state => {
            state.customer = undefined
        },
    },
})

export const CustomerActionConstant = store.actions
export default store.reducer