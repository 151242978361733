import { push } from '@lagunovsky/redux-react-router'
import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { NEW } from 'components/action/ActionConstant'
import { CardTable } from 'components/datatable/Table'
import Checkbox from 'components/form/Checkbox'
import DateTimePicker from 'components/form/DateTimePicker'
import Input from 'components/form/Input'
import ConfirmModal from 'components/modal/ConfirmModal'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import CustomerAction from 'customer/actions/CustomerAction'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useTitles from 'utils/customHook/useTitles'
import { getDate } from 'utils/DateUtil'
import { hasPermission } from 'utils/HabilitationUtil'
import { CustomerInstanceDialog } from './CustomerDescriptionApp'
import InstanceAction from 'instance/actions/InstanceAction'
import { isNil } from 'lodash'

const PopinInstance = ({
    isOpen = false,
    close = () => {},
    reload = () => {},
}) => {
    const dispatch = useDispatch()
    const {
        instances,
    } = useSelector(store => ({
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    const {
        value: isLoading,
        setTrue: loading,
        setFalse: notLoading,
    } = useBoolean(false)

    const {
        value: isChainCreate,
        toggle: toggleChainCreate,
    } = useBoolean(false)

    const {
        value: isPopinOpen,
        setTrue: onOpenPopin,
        setFalse: onClosePopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)

    const [selectedInstance, setSelectedInstance] = useState()

    const [name, setName] = useState()
    const [mnemonic, setMnemonic] = useState()
    const [description, setDescription] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [customerInstances, setCustomerInstances] = useState([])
    const [customerContributors, setCustomerContributors] = useState([])

    const formattedCustomerInstances = customerInstances.map(ci => ({
        instance: ci.instance,
        name: instances.find(i => i.id === ci.instance)?.name ?? 'Inconnue',
        type: ci.instanceType,
    }))

    useEffect(() => {
        if (isOpen) {
            setName()
            setMnemonic()
            setDescription()
            setStartDate()
            setEndDate()
            setCustomerInstances([])
            setCustomerContributors([])
        }
    }, [isOpen])

    const resetAllField = () => {
        setName()
        setMnemonic()
        setDescription()
        setStartDate()
        setEndDate()
        setCustomerInstances([])
        setCustomerContributors([])
    }

    const validate = () => {
        loading()
        dispatch(CustomerAction.createCustomer({ id: -1, name, mnemonic, description, startDate, endDate, creationDate: 0, updateDate: 0, updateLogin: '', contributors: customerContributors, instances: customerInstances }))
            .then(res => {
                if (!res) return
                reload()
                if (!isChainCreate) {
                    close()
                } else {
                    resetAllField()
                }
            })
            .finally(notLoading)
    }

    const onDeleteInstance = row => {
        setSelectedInstance(row.instance)
        onOpenConfirm()
    }

    const onEditInstance = row => {
        setSelectedInstance(row.instance)
        onOpenPopin()
    }

    const customerInstance = customerInstances.find(p => p.instance === selectedInstance)


    return (
        <Dialog
            open={isOpen}
            maxWidth={'xl'}
        >
            <BasicDialogTitle onClose={close}>
                Client
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Grid2 container spacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardContent>
                                <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                                    <Grid2 size={3}>
                                        <Input
                                            label='Nom'
                                            value={name}
                                            onChange={setName}
                                        />
                                    </Grid2>
                                    <Grid2 size={3}>
                                        <Input
                                            label='Mnémonique'
                                            value={mnemonic}
                                            onChange={setMnemonic}
                                        />
                                    </Grid2>
                                    <Grid2 size={3}>
                                        <DateTimePicker
                                            label='Date de début'
                                            value={startDate}
                                            onChange={setStartDate}
                                        />
                                    </Grid2>
                                    <Grid2 size={3}>
                                        <DateTimePicker
                                            label='Date de fin'
                                            value={endDate}
                                            onChange={setEndDate}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Input
                                            label='Description'
                                            value={description}
                                            onChange={setDescription}
                                            multiline
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={6}>
                        <CardTable
                            title='Instances'

                            rows={formattedCustomerInstances}
                            headers={[{ key: 'name', value: 'Instance' }, { key: 'type', value: 'Type d\'instance' }]}

                            actions={[{
                                icon: 'add',
                                onClick: onOpenPopin,
                                tooltip: 'Lier à une instance',
                            }]}
                            lineActions={[
                                { icon: 'delete', onClick: onDeleteInstance },
                                { icon: 'edit', onClick: onEditInstance },
                            ]}
                        />
                        <CustomerInstanceDialog
                            isOpen={isPopinOpen}
                            customerInstance={customerInstance}
                            onClose={() => {
                                setSelectedInstance()
                                onClosePopin()
                            }}
                            onValidate={newInst => {
                                const formattedParam = { ...newInst, customer: -1 }
                                setCustomerInstances(insts => {
                                    if (isNil(selectedInstance)) return [...insts, formattedParam]
                                    return insts.map(p => p.instance === selectedInstance ? formattedParam : p)
                                })
                                setSelectedInstance()
                                onClosePopin()
                            }}
                        />
                        <ConfirmModal
                            isOpen={isConfirmOpen}
                            title='Êtes-vous sûr de vouloir supprimer cette liaison ?'
                            onValidate={() => {
                                setCustomerInstances(insts => insts.filter(i => i.instance !== selectedInstance))
                                onCloseConfirm()
                            }}
                            onClose={onCloseConfirm}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <CardTable
                            title='Intervenant'

                            rows={customerContributors}
                            headers={[{ key: 'name', value: 'Nom' }, { key: 'type', value: 'Type d\'intervenant' }]}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={'Créer'}
                onValidate={validate}
                isDisable={!name || !startDate}
                isLoading={isLoading}
            >
                <Checkbox
                    label='Saisir un autre client'
                    checked={isChainCreate}
                    onToggle={toggleChainCreate}
                    labelStyle={{ color: '#555', fontWeight: 'normal' }}
                />
            </BasicDialogAction>
        </Dialog>
    )
}

PopinInstance.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    reload: PropTypes.func,
}

const HEADERS = [
    { key: 'name', value: 'Nom' },
    { key: 'mnemonic', value: 'Mnémonique' },
    { key: 'description', value: 'Description' },
    { key: 'startDate', value: 'Date de début' },
    { key: 'endDate', value: 'Date de fin' },
]

const CustomersApp = () => {
    const dispatch = useDispatch()
    const {
        user,
        customers,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
        customers: store.CustomerReducer.customers,
    }), shallowEqual)

    const {
        value: isOpenPopin,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)

    useTitles(() => [{
        label: 'Clients',
        href: '/customer',
    }], [])

    useEffect(() => {
        dispatch(InstanceAction.fetchInstances())
        dispatch(CustomerAction.fetchCustomers())
    }, [dispatch])

    const reload = () => {
        dispatch(CustomerAction.fetchCustomers())
    }

    const formattedCustomers = customers.map(customer => ({
        id: customer.id,
        name: customer.name,
        mnemonic: customer.mnemonic,
        description: customer.description,
        startDate: getDate(customer.startDate),
        endDate: getDate(customer.endDate),
    }))

    useActions(() => [{
        type: NEW,
        onClick: openPopin,
        displayed: hasPermission(user, 'customers', 'create'),
    }], [])

    return (
        <div style={{ margin: '10', paddingBottom: '50px' }}>
            <CardTable
                title={'Clients'}
                color={'#161832'}

                rows={formattedCustomers}
                headers={HEADERS}
                onClickRow={({ id }) => dispatch(push(`/customer/${id}`))}

                actions={[{
                    icon: 'replay',
                    onClick: reload,
                    tooltip: 'Recharger',
                }]}
            />
            <PopinInstance
                isOpen={isOpenPopin}
                close={closePopin}
                reload={reload}
            />
        </div>
    )
}

export default CustomersApp