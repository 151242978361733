/* eslint-disable no-console */
import ApplicationConf from 'conf/ApplicationConf'
import { keyBy, keys } from 'lodash'
import { createAppSlice } from 'store/createAppSlice'
import { IDLE, LOADING, REJECTED, SUCCEEDED } from 'store/StoreConstants'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'
import { genericPromise2 } from 'utils/ActionUtils'

const initialState = {
    status: IDLE,
    byId: {},
    allIds: [],
    byCode: {},
    allCodes: [],
}

const AdminFeatureSlice = createAppSlice({
    name: 'adminFeatures',
    initialState,
    reducers: (create) => ({
        reset: create.reducer((state, action) => initialState),

        createAdminFeature: create.asyncThunk(
            async (adminFeature, { dispatch, rejectWithValue }) => {
                try {
                    const response = await genericPromise2(ApplicationConf.gdl.adminFeatures(), { body: adminFeature, method: 'POST' })
                    dispatch(ToastActionConstant.success('Fonctionnalité créée avec succès'))
                    return response
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors de la création d\'une fonctionnalité'))
                    return rejectWithValue(err)
                }
            },
            {
                fulfilled: state => {
                    state.status = IDLE
                },
            }
        ),
        updateAdminFeature: create.asyncThunk(
            async (adminFeature, { dispatch, rejectWithValue }) => {
                try {
                    const response = await genericPromise2(ApplicationConf.gdl.adminFeatures(), { body: adminFeature, method: 'PUT' })
                    dispatch(ToastActionConstant.success('Fonctionnalité modifiée avec succès'))
                    return response
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors de la modification d\'une fonctionnalité'))
                    return rejectWithValue(err)
                }
            },
            {
                fulfilled: state => {
                    state.status = IDLE
                },
            }
        ),
        deleteAdminFeature: create.asyncThunk(
            async (id, { dispatch, rejectWithValue }) => {
                try {
                    const response = await genericPromise2(ApplicationConf.gdl.adminFeature(id), { method: 'DELETE' })
                    dispatch(ToastActionConstant.success('Fonctionnalité supprimée avec succès'))
                    return response
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors de la suppression d\'une fonctionnalité'))
                    return rejectWithValue(err)
                }
            },
            {
                fulfilled: state => {
                    state.status = IDLE
                },
            }
        ),
        fetchAdminFeatures: create.asyncThunk(
            async (_, { dispatch, rejectWithValue }) => {
                try {
                    return await genericPromise2(ApplicationConf.gdl.adminFeatures())
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors du chargement des fonctionnalités'))
                    return rejectWithValue(err)
                }
            },
            {
                pending: state => {
                    state.status = LOADING
                },
                rejected: state => {
                    state.status = REJECTED
                },
                fulfilled: (state, action) => {
                    state.status = SUCCEEDED
                    const byId = keyBy(action.payload, 'id')
                    state.byId = byId
                    state.allIds = keys(byId).map(id => parseInt(id))
                    const byCode = keyBy(action.payload, 'code')
                    state.byCode = byCode
                    state.allCodes = keys(byCode)
                },
                options: {
                    condition: (_, { getState }) => {
                        const { adminFeatures } = getState()
                        return adminFeatures.status === IDLE
                    },
                    dispatchConditionRejected: true,
                },
            }
        ),
    }),
})

export const { fetchAdminFeatures, createAdminFeature, updateAdminFeature, deleteAdminFeature } = AdminFeatureSlice.actions
export default AdminFeatureSlice