import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { NEW } from 'components/action/ActionConstant'
import { CardTable } from 'components/datatable/Table'
import Input from 'components/form/Input'
import ConfirmModal from 'components/modal/ConfirmModal'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import { isNil } from 'lodash'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useTitles from 'utils/customHook/useTitles'
import { hasPermission } from 'utils/HabilitationUtil'
import ProductFeatureSelect from './ProductFeatureSelect'
import PropTypes from 'prop-types'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'
import { createProducts, deleteProduct, fetchProducts } from 'gdl/slices/ProductSlice'

const DialogProduct = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    product = '',
}) => {
    const dispatch = useDispatch()
    const {
        products,
    } = useSelector(store => ({
        products: store.products,
    }), shallowEqual)

    const [name, setName] = useState()
    const [selectedFeatures, setSelectedFeatures] = useState([])

    useEffect(() => {
        if (!isOpen) return

        setName(product)
        setSelectedFeatures(products.byProduct[product])
    }, [isOpen, product, products.byProduct])

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
        >
            <BasicDialogTitle onClose={onClose}>
                Produit
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Grid2 container spacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardContent>
                                <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                                    <Grid2 size={3}>
                                        <Input
                                            label='Nom'
                                            value={name}
                                            onChange={newName => {
                                                setName(newName)
                                                if (products.allProducts.some(p => p === newName)) {
                                                    dispatch(ToastActionConstant.warning('Un Produit avec le même nom éxiste déjà'))
                                                }
                                            }}
                                            disabled={!!product}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <ProductFeatureSelect
                            selectedIds={selectedFeatures}
                            setSelectedIds={setSelectedFeatures}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={'Valider'}
                onValidate={() => onValidate(selectedFeatures.map(id => ({ product: name, idFeature: id })))}
                isDisable={!name}
            />
        </Dialog>
    )
}

DialogProduct.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    product: PropTypes.string,
}

const CommercialProductApp = () => {
    const dispatch = useDispatch()

    const {
        user,
        commercialFeatures,
        products,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
        commercialFeatures: store.commercialFeatures,
        products: store.products,
    }), shallowEqual)

    useTitles(() => [{
        label: 'Commercial',
        href: '/commercial',
    }, {
        label: 'Produit',
        href: '/commercial/product',
    }], [])

    const [selectedProduct, setSelectedProduct] = useState()
    const {
        value: isDialogOpen,
        setTrue: onOpenDialog,
        setFalse: onCloseDialog,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)


    useActions(() => [{
        type: NEW,
        onClick: onOpenDialog,
        displayed: hasPermission(user, 'commercial', 'create'),
    }], [])

    const onUpdate = ({ name }) => {
        setSelectedProduct(name)
        onOpenDialog()
    }
    const onDelete = ({ name }) => {
        setSelectedProduct(name)
        onOpenConfirm()
    }

    const formattedProducts = products.allProducts.map(key => ({
        name: key,
        nbModule: products.byProduct[key].filter(idFeature => isNil(commercialFeatures.byId[idFeature]?.parent)).length,
        nbFeature: products.byProduct[key].length,
    }))

    return (
        <>
            <div style={{ padding: '5px 15%' }}>
                <CardTable
                    title='Produits'

                    headers={[{ key: 'name', value: 'Nom' }, { key: 'nbModule', value: 'Nombre de module' }, { key: 'nbFeature', value: 'Nombre de fonctionnalité' }]}
                    rows={formattedProducts}
                    lineActions={[
                        {
                            icon: 'edit',
                            onClick: onUpdate,
                            displayed: hasPermission(user, 'commercial', 'update'),
                        },
                        {
                            icon: 'delete_outlined',
                            onClick: onDelete,
                            displayed: hasPermission(user, 'commercial', 'delete'),
                        },
                    ]}
                />
            </div>
            <DialogProduct
                isOpen={isDialogOpen}
                onClose={() => {
                    onCloseDialog()
                    setSelectedProduct()
                }}
                onValidate={linkProducts => {
                    dispatch(createProducts({ product: selectedProduct, linkProducts })).finally(() => {
                        dispatch(fetchProducts()).finally(() => {
                            setSelectedProduct()
                            onCloseDialog()
                        })
                    })
                }}
                product={selectedProduct}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title='Êtes-vous sûr de vouloir supprimer ce Produit ?'
                onValidate={() => {
                    dispatch(deleteProduct({ productName: selectedProduct })).then(() => {
                        dispatch(fetchProducts()).finally(() => {
                            setSelectedProduct()
                            onCloseConfirm()
                        })
                    })
                }}
                onClose={onCloseConfirm}
            />
        </>
    )
}

export default CommercialProductApp