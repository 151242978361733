import { push } from '@lagunovsky/redux-react-router'
import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { DELETE, SAVE } from 'components/action/ActionConstant'
import { CardTable } from 'components/datatable/Table'
import DateTimePicker from 'components/form/DateTimePicker'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import ConfirmModal from 'components/modal/ConfirmModal'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import CustomerAction from 'customer/actions/CustomerAction'
import { isNil, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import useTitles from 'utils/customHook/useTitles'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { hasPermission } from 'utils/HabilitationUtil'

const CustomerInstanceDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    customerInstance,
}) => {
    const {
        instances,
        customers,
    } = useSelector(store => ({
        instances: store.InstanceReducer.instances,
        customers: store.CustomerReducer.customers,
    }), shallowEqual)
    const [instance, setInstance] = useState()
    const [instanceType, setInstanceType] = useState()

    useEffect(() => {
        if (!isOpen) return

        setInstance(customerInstance?.instance)
        setInstanceType(customerInstance?.instanceType)
    }, [isOpen])

    const formattedInstances = useMemo(() => {
        const alreadySelectedInstances = uniq(customers.flatMap(c => c.instances.map(i => i.instance))).filter(id => id !== customerInstance?.instance)
        const filteredInstances = instances.filter(i => !alreadySelectedInstances.includes(i.id))
        return filteredInstances.map(i => ({ id: i.id, name: i.name }))
    }, [customerInstance?.instance, customers, instances])

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
        >
            <BasicDialogTitle onClose={onClose}>
                Instance
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={6}>
                                <Select
                                    label='Instance'
                                    items={formattedInstances}
                                    value={instance}
                                    onChange={setInstance}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    label={'Type d\'instance'}
                                    value={instanceType}
                                    onChange={setInstanceType}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isNil(customerInstance) ? 'Créer' : 'Modifier'}
                onValidate={() => onValidate({ instance, instanceType })}
                isDisable={!instance || !instanceType}
            />
        </Dialog>
    )
}

CustomerInstanceDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    customerInstance: PropTypes.shape({
        instance: PropTypes.number,
        instanceType: PropTypes.string,
    }),
}

const CustomerDescriptionApp = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const {
        user,
        customer,
        instances,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
        customer: store.CustomerReducer.customer,
        instances: store.InstanceReducer.instances,
    }), shallowEqual)

    useTitles(() => [{
        label: 'Clients',
        href: '/customer',
    }, {
        label: customer?.name ?? 'Inconnu',
        href: `/customer/${id}`,
    }, {
        label: 'Description',
        href: `/customer/${id}/description`,
    }], [id, customer])

    const {
        value: isPopinOpen,
        setTrue: onOpenPopin,
        setFalse: onClosePopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)

    const [selectedInstance, setSelectedInstance] = useState()

    const [name, setName] = useState(customer?.name)
    const [mnemonic, setMnemonic] = useState(customer?.mnemonic)
    const [description, setDescription] = useState(customer?.description)
    const [startDate, setStartDate] = useState(customer?.startDate)
    const [endDate, setEndDate] = useState(customer?.endDate)
    const [customerInstances, setCustomerInstances] = useState(customer?.instances ?? [])
    const [customerContributors, setCustomerContributors] = useState(customer?.contributors ?? [])

    useUpdateEffect(() => {
        setName(customer?.name)
        setMnemonic(customer?.mnemonic)
        setDescription(customer?.description)
        setStartDate(customer?.startDate)
        setEndDate(customer?.endDate)
        setCustomerInstances(customer?.instances ?? [])
        setCustomerContributors(customer?.contributors ?? [])
    }, [customer])

    useActions(() => {
        if (isNil(customer)) return []

        const shouldPulse = name !== customer?.name
                         || mnemonic !== customer?.mnemonic
                         || description !== customer?.description
                         || startDate !== customer?.startDate
                         || endDate !== customer?.endDate
                         || customerInstances.length !== customer?.instances.length
                         || customerInstances.some(inst => !customer?.instances.some(i => i.instance === inst.instance && i.instanceType === inst.instanceType))
        //                  || customerContributors.length !== customer?.contributors.length
        //                  || customerContributors.some(inst => !customer?.contributors.some(i => i.name === inst.name && i.type === inst.type))

        return [
            {
                type: SAVE,
                onClick: () => dispatch(CustomerAction.updateCustomer({ id: customer.id, name, mnemonic, description, startDate, endDate, creationDate: 0, updateDate: 0, updateLogin: '', contributors: customerContributors, instances: customerInstances })).then(res => {
                    if (res?.update > 0) {
                        dispatch(CustomerAction.fetchCustomer(customer.id))
                        dispatch(CustomerAction.fetchCustomers())
                    }
                }),
                pulse: shouldPulse,
                displayed: hasPermission(user, 'customers', 'update'),
            },
            {
                type: DELETE,
                onClick: () => dispatch(CustomerAction.deleteCustomer(customer.id)).then(res => {
                    if (res?.delete > 0) {
                        dispatch(push('/customer'))
                    }
                }),
                displayed: hasPermission(user, 'customers', 'delete'),
            },
        ]
    }, [customer, name, mnemonic, description, startDate, endDate, customerInstances, customerContributors])

    const formattedCustomerInstances = customerInstances.map(ci => ({
        instance: ci.instance,
        name: instances.find(i => i.id === ci.instance)?.name ?? 'Inconnue',
        type: ci.instanceType,
    }))

    const onDeleteInstance = row => {
        setSelectedInstance(row.instance)
        onOpenConfirm()
    }

    const onEditInstance = row => {
        setSelectedInstance(row.instance)
        onOpenPopin()
    }

    const customerInstance = customerInstances.find(p => p.instance === selectedInstance)

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={12}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={3}>
                                <Input
                                    label='Nom'
                                    value={name}
                                    onChange={setName}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Input
                                    label='Mnémonique'
                                    value={mnemonic}
                                    onChange={setMnemonic}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <DateTimePicker
                                    label='Date de début'
                                    value={startDate}
                                    onChange={setStartDate}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <DateTimePicker
                                    label='Date de fin'
                                    value={endDate}
                                    onChange={setEndDate}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    label='Description'
                                    value={description}
                                    onChange={setDescription}
                                    multiline
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={6}>
                <CardTable
                    title='Instances'

                    rows={formattedCustomerInstances}
                    headers={[{ key: 'name', value: 'Instance' }, { key: 'type', value: 'Type d\'instance' }]}

                    actions={[{
                        icon: 'add',
                        onClick: onOpenPopin,
                        tooltip: 'Lier à une instance',
                    }]}
                    lineActions={[
                        { icon: 'delete', onClick: onDeleteInstance },
                        { icon: 'edit', onClick: onEditInstance },
                    ]}
                />
                <CustomerInstanceDialog
                    isOpen={isPopinOpen}
                    customerInstance={customerInstance}
                    onClose={() => {
                        setSelectedInstance()
                        onClosePopin()
                    }}
                    onValidate={newInst => {
                        const formattedParam = { ...newInst, customer: customer.id }
                        setCustomerInstances(insts => {
                            if (isNil(selectedInstance)) return [...insts, formattedParam]
                            return insts.map(p => p.instance === selectedInstance ? formattedParam : p)
                        })
                        setSelectedInstance()
                        onClosePopin()
                    }}
                />
                <ConfirmModal
                    isOpen={isConfirmOpen}
                    title='Êtes-vous sûr de vouloir supprimer cette liaison ?'
                    onValidate={() => {
                        setCustomerInstances(insts => insts.filter(i => i.instance !== selectedInstance))
                        onCloseConfirm()
                    }}
                    onClose={onCloseConfirm}
                />
            </Grid2>
            <Grid2 size={6}>
                <CardTable
                    title='Intervenant'

                    rows={customerContributors}
                    headers={[{ key: 'name', value: 'Nom' }, { key: 'type', value: 'Type d\'intervenant' }]}
                />
            </Grid2>
        </Grid2>
    )
}

export default CustomerDescriptionApp
export {
    CustomerInstanceDialog,
}