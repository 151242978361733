import { Card, CardContent, Dialog, DialogContent, Grid2 } from '@mui/material'
import { SimpleFilterField, SimpleFilterFunction } from 'components/datatable/SelectionTable'
import { CardTable } from 'components/datatable/Table'
import Input from 'components/form/Input'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import { createCommercialFeature, fetchAdminFeatureLinks, updateCommercialFeature, updateLinkAdminFeatures } from 'gdl/slices/CommercialFeatureSlice'
import { isNil, keyBy, orderBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useBoolean from 'utils/customHook/useBoolean'
import { searchAllCharacters } from 'utils/StringUtil'
import PropTypes from 'prop-types'

const DialogCommercialFeature = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    commercialFeature = {},
}) => {
    const dispatch = useDispatch()
    const {
        adminFeatures,
        login = '',
    } = useSelector(store => ({
        adminFeatures: store.adminFeatures,
        login: store.AdminReducer.user?.login,
    }), shallowEqual)

    const { value: isModalOpen, setTrue: onModalOpen, setFalse: onModalClose } = useBoolean(false)

    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [price, setPrice] = useState()
    const [linkAdminFeatures, setLinkAdminFeatures] = useState([])

    useEffect(() => {
        if (!isOpen) return

        setName(commercialFeature.name)
        setDescription(commercialFeature.description)
        setPrice(commercialFeature.price)
        setLinkAdminFeatures([])
    }, [commercialFeature.name, commercialFeature.description, isOpen, commercialFeature.price])

    useEffect(() => {
        if (isNil(commercialFeature.id)) return
        dispatch(fetchAdminFeatureLinks(commercialFeature.id)).then(action => setLinkAdminFeatures(action.payload))
    }, [commercialFeature.id, dispatch])

    const onSave = () => {
        const newCommercialFeature = {
            id: 0,
            parent: undefined,
            updateDate: moment().valueOf(),
            updateLogin: login,
            ...commercialFeature,
            name,
            description,
            price,
        }
        const promise = isNil(commercialFeature.id) ? dispatch(createCommercialFeature(newCommercialFeature)) : dispatch(updateCommercialFeature(newCommercialFeature))
        promise.then(res => {
            onValidate()
            const id = res.payload.id ?? commercialFeature.id
            if (isNil(id)) return onClose()

            const links = linkAdminFeatures.map(l => ({ ...l, idFeatureCommercial: id }))
            dispatch(updateLinkAdminFeatures({ id, links })).finally(onClose)
        })
    }

    const orderedAdminFeatures = useMemo(() => {
        const formattedAdminFeatures = adminFeatures.allIds.map(id => {
            const feature = adminFeatures.byId[id]
            return {
                ...feature,
                labelSearch: [feature.code, feature.name].map(l => searchAllCharacters(l)).join('##'),
            }
        })
        return orderBy(formattedAdminFeatures, ['code', f => f.typeFeature === 'MODULE' && 2 || f.typeFeature === 'ECRAN' && 1 || 0], ['asc', 'desc'])
    }, [adminFeatures])

    const filteredAdminFeatures = linkAdminFeatures.map(l => adminFeatures.byId[l.idFeatureAdmin]).filter(f => !!f)

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
        >
            <BasicDialogTitle onClose={onClose}>
                Fonctionnalité commercial
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 size={6}>
                                <Input
                                    label='Nom'
                                    value={name}
                                    onChange={setName}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Input
                                    label='Prix'
                                    value={price}
                                    onChange={setPrice}
                                    type='int'
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    label='Description'
                                    value={description}
                                    onChange={setDescription}
                                    multiline
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
                <CardTable
                    title='Fonctionnalités'
                    actions={[{
                        icon: 'add',
                        onClick: onModalOpen,
                    }]}

                    headers={[{ key: 'code', value: 'Code' }, { key: 'name', value: 'Nom' }, { key: 'typeFeature', value: 'Type' }]}
                    rows={filteredAdminFeatures}
                />
                <SelectionTableModal
                    isOpen={isModalOpen}
                    onClose={onModalClose}
                    onValidate={newIds => {
                        const indexedLinks = keyBy(linkAdminFeatures, 'idFeatureAdmin')
                        const newLinks = newIds.map(id => indexedLinks[id] ?? {
                            idFeatureAdmin: id,
                            updateLogin: login,
                            updateDate: moment().valueOf(),
                        })
                        setLinkAdminFeatures(newLinks)
                        onModalClose()
                    }}
                    title='Sélection des fonctionnalités'

                    rows={orderedAdminFeatures}
                    selectedIds={filteredAdminFeatures.map(l => l.id)}

                    headers={[{ key: 'code', value: 'Code' }, { key: 'name', value: 'Nom' }]}
                    listTitle='Fonctionnalités'
                    selectionListTitle='Fonctionnalités sélectionné'

                    filterFunction={SimpleFilterFunction}
                    filterField={SimpleFilterField}
                />
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isNil(commercialFeature.id) ? 'Créer' : 'Modifier'}
                onValidate={onSave}
                isDisable={!name}
            />
        </Dialog>
    )
}

DialogCommercialFeature.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    commercialFeature: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
    }),
}

export default DialogCommercialFeature