import { alpha, Card, CardContent, Grid2, Tooltip, Typography } from '@mui/material'
import { RichTreeView } from '@mui/x-tree-view'
import { groupBy, orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2'
import {
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2IconContainer,
    TreeItem2Label,
    TreeItem2Root,
    TreeItem2Checkbox,
} from '@mui/x-tree-view/TreeItem2'
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon'
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider'
import DescriptionIcon from '@mui/icons-material/Description'
import PropTypes from 'prop-types'

const CustomLabel = ({ item, children, ...other }) => (
    <TreeItem2Label {...other}>
        <Grid2 container spacing={1} alignItems='center'>
            <Grid2 size='auto'>
                <Typography sx={{ fontSize: '1rem' }}>{children}</Typography>
            </Grid2>
            {item.description && (
                <Grid2 size='auto'>
                    <Tooltip title={item.description}>
                        <DescriptionIcon />
                    </Tooltip>
                </Grid2>
            )}
            <Grid2 size='grow' />
            <Grid2 size='auto'>
                <Typography sx={{ fontSize: '1rem' }}>{item.price} €</Typography>
            </Grid2>
        </Grid2>
    </TreeItem2Label>
)

const CustomTreeItem = React.forwardRef((props, ref) => {
    const {
        id, itemId, label, disabled, children, // for useTreeItem2
        ...other // for getRootProps
    } = props

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        status,
        publicAPI,
        getGroupTransitionProps,
        getCheckboxProps,
    } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })
    const item = publicAPI.getItem(itemId)

    const {
        onClick,
        ...restContentProps
    } = getContentProps()

    const {
        onChange,
        ...restCheckboxProps
    } = getCheckboxProps({ indeterminate: item.indeterminate })

    return (
        <TreeItem2Provider itemId={itemId}>
            <TreeItem2Root {...getRootProps(other)}>
                <TreeItem2Content {...restContentProps} sx={{ borderRadius: '8px' }}>
                    <TreeItem2IconContainer {...getIconContainerProps({ onClick })}>
                        <TreeItem2Icon status={status} slotProps={{ icon: { sx: { fontSize: '48px' } } }}/>
                    </TreeItem2IconContainer>
                    <CustomLabel
                        {...getLabelProps({ item, onClick: onChange })}
                    />
                    <TreeItem2Checkbox {...restCheckboxProps} onChange={onChange}/>
                </TreeItem2Content>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()}
                    sx={{
                        marginLeft: '15px',
                        paddingLeft: '8px',
                        borderLeft: `1px dashed ${alpha('#000000', 0.3)}`,
                    }}
                />}
            </TreeItem2Root>
        </TreeItem2Provider>
    )
})

const ProductFeatureSelect = ({
    selectedIds = [],
    setSelectedIds = () => { },
}) => {
    const {
        commercialFeatures,
    } = useSelector(store => ({
        commercialFeatures: store.commercialFeatures,
    }), shallowEqual)

    const [expandedItems, setExpandedItems] = useState([])

    const formattedGroupedCommercialFeatures = useMemo(() => {
        const orderedCommercialFeatures = orderBy(commercialFeatures.allIds.map(id => commercialFeatures.byId[id]), ['typeFeature', 'code'], ['desc', 'asc'])
        const groupedCommercialFeatures = groupBy(orderedCommercialFeatures, s => s.parent ?? 'headTree')

        const formatFunction = (filteredCommercialFeatures = [], depth) => {
            return filteredCommercialFeatures.map(({ id, name, description, price }) => {
                const children = formatFunction(groupedCommercialFeatures[id], depth + 1)
                return {
                    id,
                    label: name,
                    price,
                    description,

                    children,
                    depth,
                }
            })
        }

        return formatFunction(groupedCommercialFeatures.headTree, 0)
    }, [commercialFeatures.allIds, commercialFeatures.byId])

    return (
        <Grid2 container spacing={1} alignItems='flex-start'>
            {formattedGroupedCommercialFeatures.map(commercialFeature => (
                <Grid2 key={commercialFeature.code} size={6}>
                    <Card>
                        <CardContent>
                            <RichTreeView
                                items={[commercialFeature]}
                                expandedItems={expandedItems}
                                onExpandedItemsChange={(_, ids) => setExpandedItems(ids)}
                                selectedItems={selectedIds}
                                onSelectedItemsChange={(_, ids) => {
                                    setSelectedIds(ids)
                                }}
                                slots={{ item: CustomTreeItem }}
                                // slotProps={{
                                //     item: {
                                //         indeterminate: true,
                                //     },
                                // }}
                                multiSelect
                                checkboxSelection
                            />
                        </CardContent>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
    )
}

ProductFeatureSelect.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.number),
    setSelectedIds: PropTypes.func,
}

export default ProductFeatureSelect