import React from 'react'
import DateTimePicker from './DateTimePicker'
import { Grid2 } from '@mui/material'
import useBoolean from 'utils/customHook/useBoolean'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

const DateTimeRangePicker = ({
    label = '',
    value = [],
    onChange = () => {},
}) => {
    const {
        value: focused,
        setTrue: onFocus,
        setFalse: onBlur,
    } = useBoolean(false)

    const [start, end] = value

    return (
        <Grid2 container spacing={1}>
            <Grid2 size='grow'>
                <DateTimePicker
                    label={label}
                    value={start}
                    onChange={v => {
                        const newDate = [
                            !isNil(end) && !isNil(v) ? Math.min(v, end) : v,
                            end,
                        ]
                        onChange(newDate)
                    }}
                    format='DD/MM/YYYY HH:mm'
                    onFocus={onFocus}
                    onBlur={onBlur}
                    focused={focused}
                />
            </Grid2>
            <Grid2 size='auto' sx={{ marginTop: label ? '18px' : '2px' }}>
                -
            </Grid2>
            <Grid2 size='grow'>
                <DateTimePicker
                    label={label && ' '}
                    value={end}
                    onChange={v => {
                        const newDate = [
                            start,
                            !isNil(end) && !isNil(v) ? Math.max(v, end) : v,
                        ]
                        onChange(newDate)
                    }}
                    format='DD/MM/YYYY HH:mm'
                    onFocus={onFocus}
                    onBlur={onBlur}
                    focused={focused}
                />
            </Grid2>
        </Grid2>
    )
}

DateTimeRangePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number), // [startDate, endDate]
    onChange: PropTypes.func, // ([startDate, endDate]) => {}
}

export default DateTimeRangePicker