import BoundaryError from 'components/BoundaryError'
import { fetchAdminFeatures } from 'gdl/slices/AdminFeatureSlice'
import { fetchCommercialFeatures } from 'gdl/slices/CommercialFeatureSlice'
import { fetchProducts } from 'gdl/slices/ProductSlice'
import NavBar from 'NavBar'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'

const CommercialApp = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchAdminFeatures())
        dispatch(fetchCommercialFeatures())
        dispatch(fetchProducts())
    }, [dispatch])

    const links = [
        { icon: 'request_quote', tooltip: 'Fonctionnalité commercial', url: '/commercial/feature' },
        { icon: 'tune', tooltip: 'Préset de fonctionnalités', url: '/commercial/product' },
    ]
    return (
        <>
            <NavBar links={links} />
            <BoundaryError>
                <div style={{ padding: '10px', marginLeft: '65px' }}>
                    <Outlet />
                </div>
            </BoundaryError>
        </>
    )
}

export default CommercialApp