import { Card, CardContent, Dialog, DialogContent, Grid2, Typography } from '@mui/material'
import Checkbox from 'components/form/Checkbox'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { BasicDialogAction, BasicDialogTitle } from 'components/modal/DialogComponents'
import { createAdminFeature, updateAdminFeature } from 'gdl/slices/AdminFeatureSlice'
import { isNil, last } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'
import useBoolean from 'utils/customHook/useBoolean'

const TYPE_FEATURES = [
    { value: 'MODULE', label: 'Module' },
    { value: 'ECRAN', label: 'Ecran' },
    { value: 'ACTION', label: 'Action' },
]

const CodeField = ({
    parentCode,
    code,
    setCode,
}) => {
    return (
        <div style={{ height: '45px', width: '100%' }}>
            <div style={{ position: 'relative', top: '-4px' }}>
                <Typography sx={{ marginLeft: '1rem', color: '#161832', fontWeight: 'bold', fontSize: '0.9rem' }}>Code</Typography>
            </div>
            <div style={{ display: 'flex', position: 'relative', top: '-6px', alignItems: 'center' }}>
                {parentCode && (
                    <span style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
                        {parentCode}_
                    </span>
                )}
                <Input
                    value={code}
                    onChange={setCode}
                />
            </div>
        </div>
    )
}

const DialogAdminFeature = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    adminFeature = {},
}) => {
    const dispatch = useDispatch()
    const {
        adminFeatures,
        login = '',
    } = useSelector(store => ({
        adminFeatures: store.adminFeatures,
        login: store.AdminReducer.user?.login,
    }), shallowEqual)

    const [code, setCode] = useState()
    const [typeFeature, setTypeFeature] = useState()
    const [name, setName] = useState()
    const [description, setDescription] = useState()
    const [parent, setParent] = useState()
    const { value: pluggedInFrontend, setValue: setPluggedInFrontend, toggle: togglePluggedInFrontend } = useBoolean()

    const parentCode = adminFeatures.byId[parent]?.code

    useEffect(() => {
        if (!isOpen) return

        setCode(last(adminFeature.code?.split('_')))
        setTypeFeature(adminFeature.typeFeature)
        setName(adminFeature.name)
        setDescription(adminFeature.description)
        setParent(adminFeature.parent)
        setPluggedInFrontend(adminFeature.pluggedInFrontend)
    }, [adminFeature.code, adminFeature.name, adminFeature.description, adminFeature.parent, adminFeature.pluggedInFrontend, adminFeature.typeFeature, isOpen])

    useEffect(() => {
        const fullCode = isNil(parentCode) ? code : `${parentCode}_${code}`
        const isDuplicate = !isNil(adminFeatures.byCode[fullCode])
        if (isOpen && isDuplicate && adminFeature.code !== fullCode) {
            dispatch(ToastActionConstant.warning('Le code existe déjà'))
        }
    }, [parentCode, code, adminFeatures.byCode, dispatch])

    const onSave = () => {
        const newAdminFeature = {
            id: 0,
            creationDate: 0,
            updateDate: 0,
            updateLogin: login,
            ...adminFeature,
            code: isNil(parentCode) ? code : `${parentCode}_${code}`,
            typeFeature,
            name,
            description,
            parent,
            pluggedInFrontend,
            contributors: [],
        }
        const promise = isNil(adminFeature.id) ? dispatch(createAdminFeature(newAdminFeature)) : dispatch(updateAdminFeature(newAdminFeature))
        promise.then(() => {
            onValidate()
            onClose()
        })
    }

    const formattedAdminFeatures = useMemo(() => {
        return adminFeatures.allIds.map(id => ({
            id,
            label: adminFeatures.byId[id]?.code,
        }))
    }, [adminFeatures.byId, adminFeatures.allIds])
    const filteredAdminFeatures = formattedAdminFeatures.filter(af => af.id !== adminFeature.id)

    return (
        <Dialog
            open={isOpen}
            maxWidth={'lg'}
        >
            <BasicDialogTitle onClose={onClose}>
                Paramètrage
            </BasicDialogTitle>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center' style={{ paddingTop: '5px' }}>
                            <Grid2 container size={9} alignItems='center' spacing={0}>
                                <CodeField
                                    parentCode={parentCode}
                                    code={code}
                                    setCode={setCode}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label='Type de fonctionnalité'
                                    items={TYPE_FEATURES}
                                    value={typeFeature}
                                    onChange={setTypeFeature}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    label='Nom'
                                    value={name}
                                    onChange={setName}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    label='Parent'
                                    items={filteredAdminFeatures}
                                    value={parent}
                                    onChange={setParent}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    label='Description'
                                    value={description}
                                    onChange={setDescription}
                                    multiline
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <BasicDialogAction
                buttonLabel={isNil(adminFeature.id) ? 'Créer' : 'Modifier'}
                onValidate={onSave}
                isDisable={!code || !typeFeature}
            >
                <Checkbox
                    label='Branché sur Sieau'
                    checked={pluggedInFrontend}
                    onToggle={togglePluggedInFrontend}
                    labelStyle={{ color: '#555', fontWeight: 'normal' }}
                />
            </BasicDialogAction>
        </Dialog>
    )
}

export default DialogAdminFeature