import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import BoundaryError from 'components/BoundaryError'
import { Grid2, Icon, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { push } from '@lagunovsky/redux-react-router'
import { Cancel, Clear, Clock, Delete, Download, Edit, New, Other, Save, Sound, Upload } from 'components/action/Action'
import useBoolean from 'utils/customHook/useBoolean'
import aquasysLogoPath from 'assets/pictures/logoaQuasys.png'
import aquaparkPng from 'assets/pictures/aquapark.png'
import { CANCEL, CLEAR, CLOCK, DELETE, DOWNLOAD, EDIT, NEW, OTHER, SAVE, SOUND, UPLOAD } from 'components/action/ActionConstant'
import { darkBlue } from 'AppTheme'
import { hasPermission } from './utils/HabilitationUtil'

const AppBar = ({
    openNav = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        titles,
        actions,
    } = useSelector(store => ({
        titles: store.HomeReducer.titles,
        actions: store.HomeReducer.actions,
    }), shallowEqual)

    // be carefull when changing the height, you should update the css in AquaparcApp at the same time
    return (
        <Grid2 container columnSpacing={3} style={{ backgroundColor: darkBlue, height: '64px', padding: '0 20px' }} alignItems='center'>
            <Grid2 size='auto'>
                <MenuIcon sx={{ fontSize: '42px', color: 'white', cursor: 'pointer' }} onClick={openNav}/>
            </Grid2>
            <Grid2 container size='auto' spacing={0.5} alignItems='center'>
                {
                    titles.map(({ label = '', href = '' }, i) => {
                        const labelStyle = i !== titles.length - 1 ?
                            { color: 'rgba(255,255,255,0.7)', fontWeight: 'normal' }
                            : { color: 'white', fontWeight: 'bold' }

                        return (
                            <Fragment key={i} >
                                {
                                    i > 0 && (
                                        <Grid2 size='auto'>
                                            <ChevronRightIcon fontSize='medium' sx={{ color: 'rgba(255,255,255,0.7)', padding: '0 4' }} />
                                        </Grid2>
                                    )
                                }
                                <Grid2 size='auto' onClick={() => dispatch(push(href))} sx={{ cursor: 'pointer' }}>
                                    <Typography sx={{ ...labelStyle, fontSize: '1rem' }}>{label}</Typography>
                                </Grid2>
                            </Fragment>
                        )
                    })
                }
            </Grid2>
            <Grid2 size='grow' />
            <Grid2 container size='auto' spacing={1} alignItems='center'>
                {
                    actions.map(({ type, ...props }, i) => (
                        <Grid2 key={i} size='auto'>
                            {type === OTHER && (<Other {...props} />)}
                            {type === NEW && (<New {...props} />)}
                            {type === EDIT && (<Edit {...props} />)}
                            {type === CANCEL && (<Cancel {...props} />)}
                            {type === DELETE && (<Delete {...props} />)}
                            {type === SAVE && (<Save {...props} />)}
                            {type === CLOCK && (<Clock />)}
                            {type === CLEAR && (<Clear {...props} />)}
                            {type === UPLOAD && (<Upload {...props} />)}
                            {type === DOWNLOAD && (<Download {...props} />)}
                            {type === SOUND && (<Sound {...props} />)}
                        </Grid2>
                    ))
                }
                {/* <Psykokwalypse /> */}
            </Grid2>
        </Grid2>
    )
}

AppBar.propTypes = {
    openNav: PropTypes.func,
}

const SlideNavLink = ({
    name = '',
    onClick = () => {},
    icon = '',
}) => (
    <div style={{ display: 'flex', alignItems: 'center', padding: '8px 12px', cursor: 'pointer' }} onClick={onClick}>
        <Icon sx={{ color: '#777', fontSize: '24px' }}>{icon}</Icon>
        <span style={{ paddingLeft: '5px', color: 'rgba(0,0,0,0.87)' }}>{name}</span>
    </div>
)

SlideNavLink.propTypes = {
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
}

const SlideNav = ({
    isOpen = false,
    close = () => {},
}) => {
    const dispatch = useDispatch()
    const { user } = useSelector(store => ({ user: store.AdminReducer.user }), shallowEqual)

    const monitoringModules = [
        { id: 'instances', name: 'Instance', icon: 'devices', onClick: () => dispatch(push('/instance')) },
        { id: 'services', name: 'Service', icon: 'pie_chart', onClick: () => dispatch(push('/service')) },
        { id: 'timeErrors', name: 'Time Errors', icon: 'running_with_errors', onClick: () => dispatch(push('/timeError')) },
    ].filter(mod => hasPermission(user, mod.id, 'view'))

    const gdlModules = [
        { id: 'customers', name: 'Client', icon: 'person', onClick: () => dispatch(push('/customer')) },
        { id: 'settingLicenses', name: 'Paramètrage licence', icon: 'style', onClick: () => dispatch(push('/settingLicense')) },
        { id: 'commercial', name: 'Commercial', icon: 'shopping_cart', onClick: () => dispatch(push('/commercial')) },
    ].filter(mod => hasPermission(user, mod.id, 'view'))

    const adminModules = [
        { id: 'users', name: 'Utilisateurs', icon: 'people', onClick: () => dispatch(push('/user')) },
    ].filter(mod => hasPermission(user, mod.id, 'view'))

    return (
        <div
            style={{
                visibility: isOpen ? 'visible' : 'hidden',
                height: '100%',
                width: '100%',
                position: 'fixed',
                backgroundColor: isOpen ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)',
                transition: 'background-color 0.3s',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                zIndex: 9999,
            }}
            onClick={close}
        >
            <div
                style={{
                    height: '100%',
                    left: isOpen ? '0' : '-240px',
                    width: '240px',
                    transition: 'all 0.2s ease-out',
                    backgroundColor: 'white',
                    position: 'fixed',
                    top: 0,
                }}
            >
                <Grid2 container direction='column' justifyContent='space-between' sx={{ height: '100%' }}>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <div
                                style={{
                                    backgroundImage: `url('${aquaparkPng}')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    width: 176,
                                    padding: '15px 32px',
                                    backgroundColor: 'rgb(255 255 255 / 35%)',
                                    backgroundBlendMode: 'soft-light',
                                }}
                            >
                                <Grid2 container spacing={0.5}>
                                    <Grid2 size={12}>
                                        <img
                                            src={user.picture}
                                            alt=''
                                            width='50%'
                                            style={ { borderRadius: '15%' } }
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <span style={{ fontWeight: 'bold', color: 'black', cursor: 'default', textDecoration: 'none' }}>
                                            <b style={{ fontSize: 15 }}>{ user.login }</b>
                                        </span>
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <span style={{ fontWeight: 'bold', color: 'black', cursor: 'default', textDecoration: 'none', fontSize: 12 }}>
                                            { user.email }
                                        </span>
                                    </Grid2>
                                </Grid2>
                            </div>
                        </Grid2>
                        <Grid2 size={12} sx={{ borderBottom: 'solid 1px #CCC' }}>
                            <SlideNavLink onClick={() => dispatch(push('/'))} icon='home' name='Accueil' />
                        </Grid2>
                        {monitoringModules.length > 0 && (
                            <Grid2 container size={12} sx={{ borderBottom: 'solid 1px #CCC' }} direction='column'>
                                {monitoringModules.map((mod, i) => (
                                    <Grid2 key={i}>
                                        <SlideNavLink onClick={mod.onClick} icon={mod.icon} name={mod.name} />
                                    </Grid2>
                                ))}
                            </Grid2>
                        )}
                        {gdlModules.length > 0 && (
                            <Grid2 container size={12} sx={{ borderBottom: 'solid 1px #CCC' }} direction='column'>
                                {gdlModules.map((mod, i) => (
                                    <Grid2 key={i}>
                                        <SlideNavLink onClick={mod.onClick} icon={mod.icon} name={mod.name} />
                                    </Grid2>
                                ))}
                            </Grid2>
                        )}
                        {adminModules.length > 0 && (
                            <Grid2 container size={12} sx={{ borderBottom: 'solid 1px #CCC' }} direction='column'>
                                {adminModules.map((mod, i) => (
                                    <Grid2 key={i}>
                                        <SlideNavLink onClick={mod.onClick} icon={mod.icon} name={mod.name} />
                                    </Grid2>
                                ))}
                            </Grid2>
                        )}
                    </Grid2>
                    <Grid2 container>
                        <Grid2 size={12} sx={{ borderBottom: 'solid 1px #CCC', borderTop: 'solid 1px #CCC' }}>
                            <SlideNavLink onClick={() => { }} icon='power_settings_new' name='Déconnexion' />
                        </Grid2>
                        <Grid2 container size={12} sx={{ padding: '2px 0', marginTop: '20px' }} alignItems='center' justifyContent='center'>
                            <a href='https://www.aquasys.fr/' target='_blank'>
                                <img
                                    src={aquasysLogoPath}
                                    alt=''
                                    width='30px'
                                />
                            </a>
                        </Grid2>
                        <Grid2 container size={12} sx={{ padding: '2px 0' }} alignItems='center' justifyContent='center'>
                            licence
                        </Grid2>
                        <Grid2 container size={12} sx={{ padding: '2px 0' }} alignItems='center' justifyContent='center'>
                            <b>copyright</b>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </div>
        </div>
    )
}

SlideNav.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
}

const AquaparcApp = ({

}) => {
    const {
        value: isNavOpen,
        setTrue: openNav,
        setFalse: closeNav,
    } = useBoolean(false)

    return (
        <>
            <AppBar openNav={openNav} />
            <SlideNav isOpen={isNavOpen} close={closeNav} />
            <BoundaryError>
                <div
                    style={{
                        backgroundColor: '#e7eaef',
                        height: 'calc(100vh - 64px)',
                        overflowY: 'auto',
                    }}
                >
                    <Outlet />
                </div>
            </BoundaryError>
        </>
    )
}

export default AquaparcApp