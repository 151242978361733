import { isNil } from 'lodash'
import { pathApi } from './basepath'

// const localApi = 'http://localhost:8889'
// const pathApi = 'http://localhost:8889'

export default {
    auth: {
        logout: () => `${pathApi}/logout`,
        loginWithSlack: (code) => `${pathApi}/loginWithSlack?code=${code}`,
        loginWithDiscord: (code) => `${pathApi}/loginWithDiscord?code=${code}`,
        getUser: (login) => `${pathApi}/user/${login}`,
        user: () => `${pathApi}/user`,
    },
    dashboard: () => `${pathApi}/supervision/dashboard`,
    pingState: () => `${pathApi}/supervision/pingState`,
    instance: {
        all: () => `${pathApi}/supervision/instance`,
        single: id => `${pathApi}/supervision/instance/${id}`,
    },
    service: {
        all: () => `${pathApi}/supervision/service`,
        single: id => `${pathApi}/supervision/service/${id}`,
    },
    timeError: {
        dashboard: () => `${pathApi}/supervision/timeErrors/dashboard`,
        list: () => `${pathApi}/supervision/timeErrors/list`,
    },
    gdl: {
        adminFeatures: () => `${pathApi}/GDL/featureAdmin`,
        adminFeature: (id) => `${pathApi}/GDL/featureAdmin/${id}`,
        customers: () => `${pathApi}/GDL/customer`,
        customer: id => `${pathApi}/GDL/customer/${id}`,
    },
    commercialFeatures: {
        admins: () => `${pathApi}/GDL/commercialFeature/admin`,
        admin: (id) => `${pathApi}/GDL/commercialFeature/admin/${id}`,
        adminProducts: () => `${pathApi}/GDL/commercialFeature/adminProducts`,
        adminProduct: (product, featureId) => `${pathApi}/GDL/commercialFeature/adminProducts?product=${product}${isNil(featureId) ? '' : `&idFeature=${featureId}`}`,
        getLinkAdminFeatures: (id) => `${pathApi}/GDL/commercialFeature/linkFeatureAdmin${isNil(id) ? '' : `?idCommercialFeature=${id}`}`,
        linkAdminFeatures: id => `${pathApi}/GDL/commercialFeature/linkFeatureAdmin/${id}`,
    },
}
