/* eslint-disable no-console */
import ApplicationConf from 'conf/ApplicationConf'
import { groupBy, keys, mapValues } from 'lodash'
import { createAppSlice } from 'store/createAppSlice'
import { IDLE, LOADING, REJECTED, SUCCEEDED } from 'store/StoreConstants'
import { ToastActionConstant } from 'toastr/reducers/ToastrReducer'
import { genericPromise2 } from 'utils/ActionUtils'

const initialState = {
    status: IDLE,
    byProduct: {},
    allProducts: [],
}

const ProductSlice = createAppSlice({
    name: 'products',
    initialState,
    reducers: (create) => ({
        reset: create.reducer((state, action) => initialState),

        createProduct: create.asyncThunk(
            async (product, { dispatch, rejectWithValue }) => {
                try {
                    const response = await genericPromise2(ApplicationConf.commercialFeatures.adminProducts(), { body: product, method: 'POST' })
                    dispatch(ToastActionConstant.success('Produit créée avec succès'))
                    return response
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors de la création d\'un produit'))
                    return rejectWithValue(err)
                }
            },
            {
                fulfilled: state => {
                    state.status = IDLE
                },
            }
        ),
        createProducts: create.asyncThunk(
            async ({ product, linkProducts }, { dispatch, rejectWithValue }) => {
                try {
                    if (product) {
                        await genericPromise2(ApplicationConf.commercialFeatures.adminProduct(product), { method: 'DELETE' })
                    }
                    const response = await Promise.all(linkProducts.map(link => genericPromise2(ApplicationConf.commercialFeatures.adminProducts(), { body: link, method: 'POST' })))
                    dispatch(ToastActionConstant.success('Produits créés avec succès'))
                    return response
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors de la création des produits'))
                    return rejectWithValue(err)
                }
            },
            {
                fulfilled: state => {
                    state.status = IDLE
                },
            }
        ),
        deleteProduct: create.asyncThunk(
            async ({ productName, featureId }, { dispatch, rejectWithValue }) => {
                try {
                    const response = await genericPromise2(ApplicationConf.commercialFeatures.adminProduct(productName, featureId), { method: 'DELETE' })
                    dispatch(ToastActionConstant.success('Produit supprimée avec succès'))
                    return response
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors de la suppression d\'un produit'))
                    return rejectWithValue(err)
                }
            },
            {
                fulfilled: state => {
                    state.status = IDLE
                },
            }
        ),
        fetchProducts: create.asyncThunk(
            async (_, { dispatch, rejectWithValue }) => {
                try {
                    return await genericPromise2(ApplicationConf.commercialFeatures.adminProducts())
                } catch (err) {
                    console.error(err)
                    dispatch(ToastActionConstant.error('Erreur lors du chargement des produits'))
                    return rejectWithValue(err)
                }
            },
            {
                pending: state => {
                    state.status = LOADING
                },
                rejected: state => {
                    state.status = REJECTED
                },
                fulfilled: (state, action) => {
                    state.status = SUCCEEDED
                    const byProduct = groupBy(action.payload, 'product')
                    state.byProduct = mapValues(byProduct, list => list.map(p => p.idFeature))
                    state.allProducts = keys(byProduct)
                },
                options: {
                    condition: (_, { getState }) => {
                        const { products } = getState()
                        return products.status === IDLE
                    },
                    dispatchConditionRejected: true,
                },
            }
        ),
    }),
})

export const { fetchProducts, createProduct, createProducts, deleteProduct } = ProductSlice.actions
export default ProductSlice