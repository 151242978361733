module.exports = {
    INSTANCE_TYPE: [
        {
            label: 'MyliaQ',
            value: 'Myliaq',
            color: '#00cc66',
        },
        {
            label: 'SeQoya',
            value: 'Seqoya',
            color: '#0066cc',
        },
        {
            label: 'IryQua',
            value: 'Iryqua',
            color: '#993366',
        },
        {
            label: 'AcycliQ',
            value: 'Acycliq',
            color: '#cc5200',
        },
        {
            label: 'SynaptiQ',
            value: 'Synaptiq',
            color: '#600080',
        },
        {
            label: 'MantiQ',
            value: 'Mantiq',
            color: '#b3b300',
        },
        {
            label: 'YdilliQ',
            value: 'Ydilliq',
            color: '#fffc61',
        },
        {
            label: 'Coclickeau',
            value: 'Coclickeau',
            color: '#ff6666',
        },
        {
            label: 'Aqua6BO',
            value: 'Aqua6BO',
            color: '#77ff33',
        },
        {
            label: 'IAeau',
            value: 'IAeau',
            color: '#333399',
        },
    ],
}