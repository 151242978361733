import { Grid2 } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { groupBy, orderBy } from 'lodash'
import CommercialFeatureExplorer from './CommercialFeatureExplorer'
import { hasPermission } from 'utils/HabilitationUtil'
import useBoolean from 'utils/customHook/useBoolean'
import ConfirmModal from 'components/modal/ConfirmModal'
import DialogCommercialFeature from './DialogCommercialFeature'
import useActions from 'utils/customHook/useActions'
import { NEW } from 'components/action/ActionConstant'
import useTitles from 'utils/customHook/useTitles'
import { deleteCommercialFeature, fetchCommercialFeatures } from 'gdl/slices/CommercialFeatureSlice'

const CommercialFeatureApp = () => {
    const dispatch = useDispatch()
    const {
        user,
        commercialFeatures,
    } = useSelector(store => ({
        user: store.AdminReducer.user,
        commercialFeatures: store.commercialFeatures,
    }), shallowEqual)

    useTitles(() => [{
        label: 'Commercial',
        href: '/commercial',
    }, {
        label: 'Catalogue',
        href: '/commercial/feature',
    }], [])

    const [selectedCommercialFeature, setSelectedCommercialFeature] = useState({})
    const {
        value: isDialogOpen,
        setTrue: onOpenDialog,
        setFalse: onCloseDialog,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onOpenConfirm,
        setFalse: onCloseConfirm,
    } = useBoolean(false)

    useActions(() => [{
        type: NEW,
        onClick: onOpenDialog,
        displayed: hasPermission(user, 'commercial', 'create'),
    }], [])

    const formattedGroupedCommercialFeatures = useMemo(() => {
        const orderedCommercialFeatures = orderBy(commercialFeatures.allIds.map(id => commercialFeatures.byId[id]), ['typeFeature', 'code'], ['desc', 'asc'])
        const groupedCommercialFeatures = groupBy(orderedCommercialFeatures, s => s.parent ?? 'headTree')

        const formatCommercialFeatures = (filteredCommercialFeatures = []) => {
            return filteredCommercialFeatures.map(({ id, name, description, price }) => {
                const subFeatures = formatCommercialFeatures(groupedCommercialFeatures[id])
                return {
                    id,
                    label: name,
                    price,
                    description,
                    subFeatures,
                }
            })
        }

        return formatCommercialFeatures(groupedCommercialFeatures.headTree)
    }, [commercialFeatures.allIds, commercialFeatures.byId])

    const onCreate = id => {
        setSelectedCommercialFeature({ parent: id })
        onOpenDialog()
    }
    const onUpdate = id => {
        setSelectedCommercialFeature(commercialFeatures.byId[id])
        onOpenDialog()
    }
    const onDelete = id => {
        setSelectedCommercialFeature({ id })
        onOpenConfirm()
    }

    return (
        <>
            <Grid2 container spacing={1} alignItems='flex-start' sx={{ padding: '5px 15%' }}>
                {formattedGroupedCommercialFeatures.map(commercialFeature => (
                    <Grid2 key={commercialFeature.code} size={12}>
                        <CommercialFeatureExplorer
                            commercialFeature={commercialFeature}
                            actions={[
                                {
                                    icon: 'add_circle_outline',
                                    onClick: onCreate,
                                    displayed: hasPermission(user, 'commercial', 'create'),
                                },
                                {
                                    icon: 'edit',
                                    onClick: onUpdate,
                                    displayed: hasPermission(user, 'commercial', 'update'),
                                },
                                {
                                    icon: 'delete_outlined',
                                    onClick: onDelete,
                                    displayed: hasPermission(user, 'commercial', 'delete'),
                                },
                            ]}
                        />
                    </Grid2>
                ))}
            </Grid2>
            <DialogCommercialFeature
                isOpen={isDialogOpen}
                onClose={() => {
                    setSelectedCommercialFeature()
                    onCloseDialog()
                }}
                onValidate={() => dispatch(fetchCommercialFeatures())}
                commercialFeature={selectedCommercialFeature}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title='Êtes-vous sûr de vouloir supprimer cette fonctionnalité ?'
                onValidate={() => {
                    dispatch(deleteCommercialFeature(selectedCommercialFeature.id)).then(() => {
                        dispatch(fetchCommercialFeatures())
                        setSelectedCommercialFeature()
                        onCloseConfirm()
                    })
                }}
                onClose={onCloseConfirm}
            />
        </>
    )
}

export default CommercialFeatureApp