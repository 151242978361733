import { createSlice } from '@reduxjs/toolkit'
import DtoInstance from 'instance/dto/DtoInstance'

export const initialState = {
    instances: [],
    instance: undefined,
}

const store = createSlice({
    name: 'instance',
    initialState,
    reducers: {
        instances: (state, action) => {
            state.instances = action.payload
        },
        instance: (state, action) => {
            state.instance = action.payload
        },
        reset: state => {
            state.instances = initialState.instances
        },
        resetInstance: state => {
            state.instance = undefined
        },
    },
})

export const InstanceActionConstant = store.actions
export default store.reducer