import { createRouterReducer, createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { createHashHistory } from 'history'
import logger from 'redux-logger'
import ToastrReducer from 'toastr/reducers/ToastrReducer'
import WaitReducer from 'wait/reducers/WaitReducer'
import HomeReducer from 'home/reducers/HomeReducer'
import AdminReducer from 'admin/reducers/AdminReducer'
import CustomerReducer from 'customer/reducers/CustomerReducer'
import ServiceReducer from 'service/reducers/ServiceReducer'
import InstanceReducer from 'instance/reducers/InstanceReducer'
import TimeErrorReducer from 'timeError/reducers/TimeErrorReducer'
import { combineSlices, configureStore } from '@reduxjs/toolkit'
import AdminFeatureSlice from 'gdl/slices/AdminFeatureSlice'
import CommercialFeatureSlice from 'gdl/slices/CommercialFeatureSlice'
import ProductSlice from 'gdl/slices/ProductSlice'

export const history = createHashHistory()

const appSlices = combineSlices(
    AdminFeatureSlice,
    CommercialFeatureSlice,
    ProductSlice,
    {
        router: createRouterReducer(history),
        ToastrReducer,
        WaitReducer,
        HomeReducer,
        AdminReducer,
        CustomerReducer,
        ServiceReducer,
        InstanceReducer,
        TimeErrorReducer,
    }
)

const AppStore = configureStore({
    reducer: appSlices,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
        logger,
        createRouterMiddleware(history),
    ],
    devTools: 'development',
})

export default AppStore
