import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getDate } from '../../utils/DateUtil'
import KwaKeUpMap from './KwaKeUpMap'
import { Grid2, Icon, Typography } from '@mui/material'
import ApplicationConf from 'conf/ApplicationConf'
import TabList from 'components/tab/TabList'
import useTitles from 'utils/customHook/useTitles'
import useFetchTimeout from 'utils/customHook/useFetchTimeout'
import { IDLE, REJECTED } from 'store/StoreConstants'
import DtoKwaKeUpResult from '../dto/DtoKwaKeUpResult'
import moment from 'moment'
import KwakeUpTable from './KwakeUpTable'
import TimeErrorTable from './TimeErrorTable'
import UnupdatedTable from './UnupdatedTable'
import useActions from 'utils/customHook/useActions'
import { SOUND } from 'components/action/ActionConstant'
import { countBy, differenceWith, groupBy, isNil, keys, toLower, uniqBy } from 'lodash'
import soundCaBugNormal from 'assets/sound/caBugNormal.mp3'
import soundCKC from 'assets/sound/ckc.mp3'
import soundCToutRouge from 'assets/sound/cToutRouge.mp4'
import soundGVu from 'assets/sound/gVu.mp4'
import soundCTropCalme from 'assets/sound/cTropCalme.mp4'
import soundCOk from 'assets/sound/cOk.mp4'
import soundHouston from 'assets/sound/houstononaunpbm.mp4'
import soundSerge from 'assets/sound/serge.mp3'
import { shallowEqual, useSelector } from 'react-redux'
import useSound from 'utils/customHook/useSound'
import RadioButtons from 'components/form/RadioButtons'
import { BRANCH, DISPLAY_MODE, getBranchColor, STATUS } from 'dashboard/utils/DashboardUtils'
import Chip from 'components/Chip'
import { INSTANCE_TYPE } from 'instance/constants/InstanceConstants'
import Input from 'components/form/Input'

const MAP = 'MAP'
const TABLE = 'TABLE'
const UNUPDATED_TABLE = 'UNUPDATED_TABLE'
const TIME_ERROR = 'TIME_ERROR'

const Notification = ({
    results = [],
}) => {
    const [playCToutRouge] = useSound(soundCToutRouge)
    const [playCKC] = useSound(soundCKC)
    const [playCaBugNormal] = useSound(soundCaBugNormal)
    const [playGVu] = useSound(soundGVu)
    const [playCTropCalme] = useSound(soundCTropCalme)
    const [playCOk] = useSound(soundCOk)
    const [playHouston] = useSound(soundHouston)
    const [playSerge] = useSound(soundSerge)

    const {
        isMute,
    } = useSelector(store => ({
        isMute: store.HomeReducer.isMute,
    }), shallowEqual)
    const [prevError, setPrevError] = useState([])
    const [dateLastError, setDateLastError] = useState(moment())

    useEffect(() => {
        if (isMute) return

        const errors = results.filter(r => !r.isRunning)
        const newError = differenceWith(errors, prevError, (e, p) => e.instance.id === p.instance.id && e.service.id === p.service.id)
        setPrevError(errors)

        if (!errors.length && prevError.length) {
            if (Math.random() < 0.01) playSerge()
            else playCOk()
        }

        if (!newError.length) {
            const now = moment()
            const time = moment.duration(now.diff(dateLastError)).valueOf()
            if (time > 3600000) { // 1h
                setDateLastError(now)
                playCTropCalme()
            }
        }

        if (newError.length) {
            setDateLastError(moment().valueOf())
            const uniqServer = uniqBy(newError, 'instance.server').map(e => e.instance.server)
            const isServerError = uniqServer.some(server => {
                const allServerError = errors.filter(e => e.instance.server === server)
                const allService = results.filter(r => r.instance.server === server)
                return allServerError.length === allService.length
            })
            if (isServerError) { // crash server
                playCToutRouge()
                return
            }

            const uniqInstance = uniqBy(newError, 'instance.id').map(e => e.instance.id)
            const isInstanceError = uniqInstance.some(instance => {
                const allInstanceError = errors.filter(e => e.instance.id === instance)
                const allService = results.filter(r => r.instance.id === instance)
                return allInstanceError.length === allService.length
            })
            if (isInstanceError) { // crash instance
                playCKC()
                return
            }

            // todo add branch info on error
            const rand = Math.random()
            if (rand < 0.80) playHouston()
            else if (rand < 0.95) playCaBugNormal()
            else playGVu()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results])

    return
    // return (
    //     <Grid2 container spacing={1} style={{ paddingBottom: '20px' }}>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playCToutRouge}>CToutRouge</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playCKC}>CKC</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playCaBugNormal}>CaBugNormal</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playGVu}>gVu</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playCTropCalme}>cTropCalme</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playCOk}>cOk</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playSerge}>serge</Button></Grid2>
    //         <Grid2 size='auto'><Button variant='contained' color='primary' onClick={playHouston}>houston</Button></Grid2>
    //     </Grid2>
    // )
}

Notification.propTypes = {
    results: PropTypes.arrayOf(PropTypes.instanceOf(DtoKwaKeUpResult)),
}

const DashboardApp = ({

}) => {
    const [tab, setTab] = useState(MAP)
    const [filter, setFilter] = useState({})

    const [displayMode, setDisplayMode] = useState(DISPLAY_MODE.BACK)

    const [search, setSearch] = useState('')
    const [branch, setBranch] = useState()
    const [instanceType, setInstanceType] = useState()

    useActions(() => [{
        type: SOUND,
    }], [])

    useTitles(() => [{
        label: 'Dashboard',
        href: '/',
    }], [])

    const {
        data: { instances = [], services = [] } = {},
        status,
        resultDate,
        reset,
        clear,
    } = useFetchTimeout(ApplicationConf.dashboard(), undefined, 15000, { skipLoadingState: true })

    const filteredInstances = useMemo(() => {
        const formattedSearch = toLower(search)
        const filterBySearch = formattedSearch ? instances.filter(i => toLower(i.name).includes(formattedSearch)) : instances
        const filterByBranch = instanceType ? filterBySearch.filter(i => (i.instanceType ?? 'Inconnue') === instanceType) : filterBySearch
        return branch ? filterByBranch.filter(i => (i.branch ?? BRANCH.UNKNOWN) === branch) : filterByBranch
    }, [branch, instanceType, instances, search])

    useEffect(() => {
        if (tab === TIME_ERROR) {
            clear()
            return
        }
        reset()
    }, [tab])

    const groundByInstanceTypes = groupBy(instances, i => i.instanceType ?? 'Inconnue')
    const groundByBranch = groupBy(instances, i => i.branch ?? BRANCH.UNKNOWN)

    return (
        <div style={{ margin: '10', paddingBottom: '20px' }}>
            <Notification results={services}/>
            <TabList
                controlTab={tab}
                setControlTab={setTab}
                tabs={[
                    {
                        constant: MAP,
                        label: 'Cartographie',
                        icon: 'map',
                    },
                    {
                        constant: TABLE,
                        label: 'Tableau',
                        icon: 'table_view',
                    },
                    {
                        constant: UNUPDATED_TABLE,
                        label: 'Tableau services non mis à jour',
                        icon: 'playlist_remove',
                    },
                    {
                        constant: TIME_ERROR,
                        label: 'Time error',
                        icon: 'running_with_errors',
                    },
                ]}
                headerComponent={resultDate && (
                    <Grid2 container columnSpacing={3} sx={{ margin: '0 10 5 10' }}>
                        {status === REJECTED && (
                            <Grid2 container size='auto' direction='column'>
                                <Grid2 container spacing={0.5}>
                                    <Icon sx={{ color: 'red' }}>warning</Icon>
                                    Impossible de récupérer l'état des serveurs
                                </Grid2>
                            </Grid2>
                        )}
                        <Grid2 container size='grow' columnSpacing={1} rowSpacing={0.2}>
                            <Grid2 container size={12}>
                                {keys(groundByInstanceTypes).map(key => {
                                    const nb = groundByInstanceTypes[key].length
                                    const {
                                        error = 0,
                                        warning = 0,
                                        clean = 0,
                                    } = countBy(groundByInstanceTypes[key], i => {
                                        if (i.statusBackend === STATUS.KO || i.statusDB === STATUS.KO) return 'error'
                                        if (i.statusBackend === STATUS.OK && i.statusDB === STATUS.OK) return 'clean'
                                        return 'warning'
                                    })
                                    const type = INSTANCE_TYPE.find(t => t.value === key)
                                    return (
                                        <Grid2 key={key} size='auto'>
                                            <Chip
                                                label={`${nb} ${type?.label ?? 'Inconnue'}`}
                                                color={isNil(instanceType) || instanceType === key ? type?.color : '#aaa'}
                                                tooltip={`${error} en erreur, ${warning} à surveiller, ${clean} sans problème)`}
                                                onClick={() => setInstanceType(prev => prev === key ? undefined : key)}
                                            />
                                        </Grid2>
                                    )
                                })}
                            </Grid2>
                            <Grid2 container size={12}>
                                <Grid2 size='auto'>
                                    <Chip
                                        label={`${(uniqBy(instances, 'server').length ?? 0) + 6} Serveur`}
                                        color='#a22ade'
                                    />
                                </Grid2>
                                {keys(groundByBranch).map(key => {
                                    const nb = groundByBranch[key].length
                                    const {
                                        error = 0,
                                        warning = 0,
                                        clean = 0,
                                    } = countBy(groundByBranch[key], i => {
                                        if (i.statusBackend === STATUS.KO || i.statusDB === STATUS.KO) return 'error'
                                        if (i.statusBackend === STATUS.OK && i.statusDB === STATUS.OK) return 'clean'
                                        return 'warning'
                                    })
                                    return (
                                        <Grid2 key={key} size='auto'>
                                            <Chip
                                                label={`${nb} ${key}`}
                                                color={isNil(branch) || branch === key ? getBranchColor(key) : '#aaa'}
                                                tooltip={`${error} en erreur, ${warning} à surveiller, ${clean} sans problème)`}
                                                onClick={() => setBranch(prev => prev === key ? undefined : key)}
                                            />
                                        </Grid2>)
                                })}
                            </Grid2>
                        </Grid2>
                        <Grid2 size={2}>
                            <Input
                                label='Rechercher'
                                value={search}
                                onChange={setSearch}
                            />
                        </Grid2>
                        <Grid2 size='auto'>
                            <RadioButtons
                                label={'Mode d\'affichage'}
                                items={[{ value: DISPLAY_MODE.BACK, label: 'Back-end' }, { value: DISPLAY_MODE.DB, label: 'Base de donnée' }, { value: DISPLAY_MODE.FRONT, label: 'Front-end' }]}
                                value={displayMode}
                                onChange={setDisplayMode}
                            />
                        </Grid2>
                    </Grid2>
                )}
            >
                {t => {
                    if (status === IDLE) {
                        return (
                            <div>
                                Chargement en cours...
                            </div>
                        )
                    }
                    if (t === TIME_ERROR) {
                        return (
                            <TimeErrorTable />
                        )
                    }
                    if (services.length === 0) {
                        return (
                            <div>
                                Aucune donnée à afficher
                            </div>
                        )
                    }
                    return (
                        <>
                            {t === MAP && (
                                <>
                                    {filteredInstances.length === 0 && (
                                        <Typography variant='h5' gutterBottom>Aucune instances à afficher</Typography>
                                    )}
                                    <KwaKeUpMap
                                        instances={filteredInstances}
                                        onClick={info => {
                                            setFilter(info)
                                            setTab(TABLE)
                                        }}
                                        displayMode={displayMode}
                                    />
                                </>
                            )}
                            {t === TABLE && (<KwakeUpTable results={services} filter={filter} setFilter={setFilter} />)}
                            {t === UNUPDATED_TABLE && (<UnupdatedTable results={services} />)}
                        </>
                    )
                }}
            </TabList>
        </div>
    )
}

export default DashboardApp
